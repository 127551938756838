// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "fixed",
      "inset-y-0",
      "z-50 flex",
      "w-72",
      "flex-col"
    ]);

var innerContainer = Cx.cx([
      "flex",
      "grow",
      "flex-col",
      "gap-y-5",
      "overflow-y-auto",
      "border-r",
      "border-gray-200",
      "bg-white",
      "px-6",
      "pb-4"
    ]);

var logoContainer = Cx.cx([
      "flex",
      "h-16",
      "shrink-0",
      "items-center"
    ]);

var navContainer = Cx.cx([
      "flex",
      "flex-1",
      "flex-col"
    ]);

var navList = Cx.cx([
      "flex",
      "flex-1",
      "flex-col",
      "gap-y-7"
    ]);

var icon = Cx.cx([
      "w-6",
      "h-6"
    ]);

var link = Cx.cx([
      "text-indigo-600",
      "group",
      "flex",
      "gap-x-3",
      "rounded-md",
      "p-2",
      "text-sm",
      "leading-6",
      "font-semibold"
    ]);

var currentLink = Cx.cx([
      link,
      "bg-gray-50"
    ]);

export {
  container ,
  innerContainer ,
  logoContainer ,
  navContainer ,
  navList ,
  icon ,
  link ,
  currentLink ,
}
/* container Not a pure module */
