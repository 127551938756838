// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as NavigationStyles from "../../styles/NavigationStyles.res.js";

function NavigationMenuItem(props) {
  return React.createElement("a", {
              className: NavigationStyles.bookMenuItem,
              role: "menuitem",
              tabIndex: -1,
              href: props.href
            }, props.text);
}

var Css;

var make = NavigationMenuItem;

export {
  Css ,
  make ,
}
/* react Not a pure module */
