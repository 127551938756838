// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as HighlightJs from "../../../bindings/highlightjs/HighlightJs.res.js";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterCodeBlock(props) {
  var title = props.title;
  return React.createElement("div", {
              className: ChapterStyles.Page.codeBlock
            }, title !== undefined ? React.createElement("div", {
                    className: ChapterStyles.Page.codeBlockTitle
                  }, Js_option.getExn(Caml_option.valFromOption(title))) : null, React.createElement(HighlightJs.make, {
                  language: props.language,
                  element: props.children,
                  className: Cx.cx([title !== undefined ? "rounded-b" : "rounded"])
                }));
}

var Css;

var make = ChapterCodeBlock;

export {
  Css ,
  make ,
}
/* react Not a pure module */
