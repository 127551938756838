// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as TypeIt from "../../../bindings/TypeIt.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "h-screen",
      "w-screen",
      "bg-white",
      "sm:p-1",
      "md:p-5",
      "lg:py-10",
      "lg:px-0"
    ]);

var bookIcon = Cx.cx([
      "absolute",
      "w-6",
      "h-6",
      "right-6",
      "top-6",
      "md:right-6",
      "lg:right-20",
      "hover:text-cyan-500"
    ]);

var bookMenu = Cx.cx([
      "absolute",
      "right-20",
      "top-12",
      "z-10",
      "mt-2",
      "w-60",
      "origin-top-right",
      "rounded-md",
      "bg-white",
      "py-1",
      "shadow-lg",
      "ring-1",
      "ring-black",
      "ring-opacity-5",
      "focus:outline-none",
      "right-6",
      "md:right-6",
      "lg:right-20"
    ]);

var typeIt = Cx.cx([
      TypeIt.Style.text,
      "text-center",
      "text-xl"
    ]);

var author = Cx.cx([
      "text-blue-gray-400",
      "text-center",
      "text-lg"
    ]);

var link = Cx.cx([
      "text-cyan-500",
      "font-medium",
      "hover:text-cyan-300"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-col",
      "gap-16",
      "w-3/4",
      "max-w-4xl",
      "m-10",
      "pb-10",
      "lg:w-2/3"
    ]);

var section = Cx.cx([
      "flex",
      "flex-col",
      "gap-6"
    ]);

var sectionTitle = Cx.cx([
      "text-4xl",
      "text-blue-gray-800",
      "font-semibold"
    ]);

var subTitle = Cx.cx([
      "text-2xl",
      "text-blue-gray-700",
      "font-semibold"
    ]);

var miniTitle = Cx.cx([
      "text-xl",
      "text-blue-gray-600",
      "italic"
    ]);

var paragraph = Cx.cx([
      "sm:text-sm",
      "md:text-base",
      "lg:text-lg",
      "text-blue-gray-600"
    ]);

var codeBlock = Cx.cx([
      "md:text-sm",
      "lg:text-md",
      "bg-blue-gray-100",
      "rounded",
      "shadow-md",
      "shadow-blue-gray-300"
    ]);

var codeBlockTitle = Cx.cx([
      "text-md",
      "text-blue-gray-900",
      "font-medium p-4"
    ]);

var fileName = Cx.cx([
      "text-base",
      "bg-blue-gray-50",
      "text-magenta-vivid-400",
      "rounded-sm",
      "p-1"
    ]);

var Page = {
  container: container$1,
  section: section,
  sectionTitle: sectionTitle,
  subTitle: subTitle,
  miniTitle: miniTitle,
  paragraph: paragraph,
  codeBlock: codeBlock,
  codeBlockTitle: codeBlockTitle,
  fileName: fileName
};

var container$2 = Cx.cx([
      "font-light",
      "rounded-md",
      "text-lg",
      "shadow-md"
    ]);

var blueContainer = Cx.cx([
      container$2,
      "bg-gray-900",
      "shadow-blue-gray-300"
    ]);

var redContainer = Cx.cx([
      container$2,
      "bg-red-900",
      "shadow-red-300"
    ]);

var title = Cx.cx([
      "font-semibold",
      "p-5"
    ]);

var blueTitle = Cx.cx([
      title,
      "text-blue-gray-100"
    ]);

var redTitle = Cx.cx([
      title,
      "text-red-100"
    ]);

var contents = Cx.cx([
      "p-5",
      "flex",
      "flex-col",
      "gap-5",
      "rounded-b-md"
    ]);

var blueContents = Cx.cx([
      contents,
      "bg-blue-gray-600",
      "text-blue-gray-100"
    ]);

var redContents = Cx.cx([
      contents,
      "bg-red-500",
      "text-white"
    ]);

var numberedList = Cx.cx([
      "px-5",
      "list-decimal"
    ]);

var Box = {
  container: container$2,
  blueContainer: blueContainer,
  redContainer: redContainer,
  title: title,
  blueTitle: blueTitle,
  redTitle: redTitle,
  contents: contents,
  blueContents: blueContents,
  redContents: redContents,
  numberedList: numberedList
};

var container$3 = Cx.cx([
      "relative",
      "align-center",
      "flex",
      "justify-center",
      "items-center",
      "rounded"
    ]);

var image = Cx.cx([
      "w-full",
      "rounded",
      "shadow-md",
      "shadow-blue-gray-300"
    ]);

var imageHalf = Cx.cx([
      "w-1/2",
      "rounded",
      "shadow-md",
      "shadow-blue-gray-300"
    ]);

var caption = Cx.cx([
      "w-full",
      "text-center",
      "text-blue-gray-500"
    ]);

var overlayText = Cx.cx([
      "opacity-0",
      "hover:opacity-100",
      "duration-300",
      "absolute",
      "inset-0",
      "z-10",
      "flex",
      "justify-center",
      "items-center",
      "text-6xl",
      "text-white",
      "font-semibold",
      "bg-black/40"
    ]);

var $$Image = {
  container: container$3,
  image: image,
  imageHalf: imageHalf,
  caption: caption,
  overlayText: overlayText
};

export {
  container ,
  bookIcon ,
  bookMenu ,
  typeIt ,
  author ,
  link ,
  Page ,
  Box ,
  $$Image ,
}
/* container Not a pure module */
