// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as HomeHero from "./components/hero/HomeHero.res.js";
import * as $$Navigation from "../views/navigation/Navigation.res.js";

function Home$default(props) {
  return React.createElement("div", undefined, React.createElement($$Navigation.make, {}), React.createElement(HomeHero.make, {}));
}

var Css;

var $$default = Home$default;

export {
  Css ,
  $$default as default,
}
/* react Not a pure module */
