// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "justify-center",
      "w-full",
      "h-full",
      "p-20",
      "bg-blue-gray-700"
    ]);

var fakeBrowser = Cx.cx([
      "w-full",
      "h-full",
      "border",
      "border-solid",
      "border-blue-gray-900",
      "bg-white",
      "rounded",
      "overflow-hidden",
      "flex",
      "flex-col",
      "shadow-xl"
    ]);

var fakeBrowserHeader = Cx.cx([
      "bg-blue-gray-200",
      "h-[44px]",
      "flex",
      "items-center",
      "hover:bg-blue-gray-400"
    ]);

var actionButtonsSpan = Cx.cx([
      "w-3",
      "h-3",
      "inline-block",
      "ml-2.5",
      "rounded-xl",
      "float-left"
    ]);

var actionButtonRed = Cx.cx([
      actionButtonsSpan,
      "bg-red-vivid-400",
      "hover:bg-red-vivid-600"
    ]);

var actionButtonOrange = Cx.cx([
      actionButtonsSpan,
      "bg-purple-300"
    ]);

var actionButtonGreen = Cx.cx([
      actionButtonsSpan,
      "bg-lime-green-500"
    ]);

var addressBar = Cx.cx([
      "bg-white",
      "py-1",
      "px-2.5",
      "rounded",
      "flex-1"
    ]);

var input = Cx.cx([
      "w-full",
      "border-none",
      "p-0",
      "outline-none",
      "text-xs"
    ]);

var settingMore = Cx.cx([
      "min-w-[100px]",
      "text-right",
      "pr-[15px]"
    ]);

var moreButton = Cx.cx([
      "relative",
      "inline-block",
      "h-[3px]",
      "w-5",
      "rounded-sm",
      "bg-blue-gray-900",
      "align-middle",
      "before:content-['']",
      "before:absolute",
      "before:left-0",
      "before:top-[-6px]",
      "after:content-['']",
      "after:absolute",
      "after:left-0",
      "after:bottom-[-6px]"
    ]);

var fakeWindowBody = Cx.cx([
      "flex-1",
      "overflow-hidden"
    ]);

var iframe = Cx.cx([
      "w-full",
      "h-full"
    ]);

var actionButtons = "min-w-[100px]";

export {
  container ,
  fakeBrowser ,
  fakeBrowserHeader ,
  actionButtons ,
  actionButtonsSpan ,
  actionButtonRed ,
  actionButtonOrange ,
  actionButtonGreen ,
  addressBar ,
  input ,
  settingMore ,
  moreButton ,
  fakeWindowBody ,
  iframe ,
}
/* container Not a pure module */
