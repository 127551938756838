// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Basic_railsPng from "../images/basic_rails.png";

var basicRails = Basic_railsPng;

function SectionGettingStarted(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Getting Started"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Before we dive in, I'd like to share a few notes about using this book."
                }), React.createElement(ChapterParagraph.make, {
                  children: "First, I didn't include a copy and paste button for the code\n        examples. I strongly recommend typing the code yourself as it\n        could help with retention of the material. Of course, if you're determined to\n        do so anyway, you can highlight the code, copy and paste it."
                }), React.createElement(ChapterParagraph.make, {
                  children: "In addition, I often provide previews of code in action code in action.\n        I did this so you could see how the code it suppsed to look and act\n        in an actual browser. If you hover over an image, you might see a\n        \"Click to Preview\" option. Click it and new view will pop up.\n        To get out of it, simply click anywhere in the browser navigation\n        bar."
                }), React.createElement(ChapterParagraph.make, {
                  children: "Finally, the book isn't entirely complete. It gets you far enough to\n        integrate Rails with ReScript and containerize and host the site. I\n        plan more chapters in the future if there's enough interest. Some future\n        chapters might include subjects such as Forms, GraphQL, REST, or testing\n        with Cypress. If anything specific interests you, send me an email."
                }));
}

var Section;

var Title;

var P;

var Code;

var Listings;

var $$File;

var make = SectionGettingStarted;

export {
  basicRails ,
  Section ,
  Title ,
  P ,
  Code ,
  Listings ,
  $$File ,
  make ,
}
/* basicRails Not a pure module */
