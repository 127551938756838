// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Routes from "../../../routes/Routes.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Docker_get_startedPng from "../images/docker_get_started.png";

var dockerGetStarted = Docker_get_startedPng;

function SectionInstallDocker(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Install Docker Desktop"
                }), React.createElement(ChapterParagraph.make, {
                  children: "If you already have Docker Desktop installed, you can skip this step. There are several\n        ways to install Docker depending on your system, but we'll go through a basic\n        Docker Desktop installation."
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Visit the Docker ", React.createElement(ChapterLink.make, {
                      href: "https://www.docker.com/get-started/",
                      text: "Get Started "
                    }), " page and download the installer for your system. Once the installer is downloaded,\n        double-click the installer to start the installation process. Follow the prompts to\n        install Docker Desktop on your system."), React.createElement(ChapterImage.make, {
                  id: "dockerGetStarted",
                  caption: "Figure 4.1: Docker Get Started",
                  imageType: "Static",
                  image: dockerGetStarted
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "For more details on installing Docker Desktop, visit ", React.createElement(ChapterLink.make, {
                      href: Routes.Docker.desktopOverview,
                      text: "Docker Desktop Overview"
                    }), ". ", "Once Docker has been installed, make sure it's running on your system, otherwise\n        the build steps won't work properly."));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var $$Image;

var make = SectionInstallDocker;

export {
  dockerGetStarted ,
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  $$Image ,
  make ,
}
/* dockerGetStarted Not a pure module */
