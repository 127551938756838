// Generated by ReScript, PLEASE EDIT WITH CARE


var simpleGemfiled = {
  number: "1.1",
  text: "A simplified Gemfile"
};

var yarnYml = {
  number: "1.2",
  text: "Updated .yarnrc.yml file"
};

var ignoreYarn = {
  number: "1.3",
  text: "Updated .gitignore file"
};

var basicPagesController = {
  number: "1.4",
  text: "A basic PagesController"
};

var basicHelloWorld = {
  number: "1.5",
  text: "A basic helloworld view file"
};

var routes = {
  number: "1.6",
  text: "Update the routes.rb file with helloworld"
};

var shakaGemfile = {
  number: "1.7",
  text: "Gemfile with React on Rails and Shakapacker"
};

var gitignore = {
  number: "1.8",
  text: "Updated .gitignore file"
};

var packageJson = {
  number: "1.9",
  text: "Update the package.json file with the following name and packageManager"
};

var applicationJs = {
  number: "1.9",
  text: "New packs/application.js file"
};

var routesAsIs = {
  number: "1.10",
  text: "Keep the routes.rb file as is"
};

var helloWorldHtml = {
  number: "1.11",
  text: "Add component to the helloworld.html.erb view"
};

var pagesController = {
  number: "1.12",
  text: "Updated pagescontroller.rb"
};

var updatedHelloWorldJsx = {
  number: "1.13",
  text: "Updated HelloWorld.jsx"
};

export {
  simpleGemfiled ,
  yarnYml ,
  ignoreYarn ,
  basicPagesController ,
  basicHelloWorld ,
  routes ,
  shakaGemfile ,
  gitignore ,
  packageJson ,
  applicationJs ,
  routesAsIs ,
  helloWorldHtml ,
  pagesController ,
  updatedHelloWorldJsx ,
}
/* No side effect */
