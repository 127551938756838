// Generated by ReScript, PLEASE EDIT WITH CARE


var tailwindGemfile = {
  number: "3.1",
  text: "Add Tailwind to the Gemfile"
};

var bsConfigClassnames = {
  number: "3.2",
  text: "Update rescript.json with rescript-classnames"
};

var tailwindConfig = {
  number: "3.3",
  text: "Updated tailwind.config.js with tailwindcss-logical"
};

var helloWorldStyles = {
  number: "3.4",
  text: "Create HelloWorldStyles.res"
};

var helloWorldComponentStyles = {
  number: "3.5",
  text: "Updated HelloWorld.res file with styles."
};

var updatedApplicationHtml = {
  number: "3.6",
  text: "Updated application.html.erb layout file"
};

var tailwindThemeConfig = {
  number: "3.7",
  text: "Updated tailwind.config.js with theme configuration"
};

var homepageController = {
  number: "3.8",
  text: "Step 1 - Update pages_controller"
};

var homepageRoutes = {
  number: "3.9",
  text: "Step 2 - Updated routes.rb with new home page"
};

var homepageHtml = {
  number: "3.10",
  text: "Step 3 - Create home page view"
};

var addHomepageRes = {
  number: "3.11",
  text: "Step 1 - Add home page ReScript file"
};

var homepageRes = {
  number: "3.12",
  text: "Step 1 (cont) - Update the new ReScript file with a basic component"
};

var declareHomepage = {
  number: "3.13",
  text: "Step 2 - Declare the new ReScript file in application.js and application-server.js"
};

var updateHomepageView = {
  number: "3.14",
  text: "Step 3 - Update the home page view to render the ReScript component"
};

var homepageStyles = {
  number: "3.15",
  text: "Update homepage styles"
};

var homepageResColor = {
  number: "3.16",
  text: "Update the home page to give it some style"
};

export {
  tailwindGemfile ,
  bsConfigClassnames ,
  tailwindConfig ,
  helloWorldStyles ,
  helloWorldComponentStyles ,
  updatedApplicationHtml ,
  tailwindThemeConfig ,
  homepageController ,
  homepageRoutes ,
  homepageHtml ,
  addHomepageRes ,
  homepageRes ,
  declareHomepage ,
  updateHomepageView ,
  homepageStyles ,
  homepageResColor ,
}
/* No side effect */
