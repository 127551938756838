// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Version from "../../../models/Version.res.js";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionDevEnv(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Development Environment"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "This book assumes that you have an existing Rails environment setup or know\n        how to create one. If you are new to Rails, such as a React or ReScript developer,\n        it is highly recommended that you start with the ", React.createElement(ChapterLink.make, {
                      href: "https://www.railstutorial.org/",
                      text: "Ruby on Rails Tutorial by Michael Hartl"
                    }), "."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "If you're looking for professional support for the Rails, React, ReScript\n        stack, consider reaching out to ", React.createElement(ChapterLink.make, {
                      href: "https://www.shakacode.com/react-on-rails-pro/",
                      text: "Shakacode"
                    }), "."), React.createElement(ChapterParagraph.make, {
                  children: "An assumption is made that you also understand how to set up and manage Node and Yarn.\n        Before moving forward, set up your environment as needed."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Versions",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "This book was conceived as a companion to the Ruby on Rails Tutorial.\n        Therefore, we will attempt to keep the Ruby and Rails versions in line\n        with the original tutorial."
                }), React.createElement(ChapterParagraph.make, {
                  children: "It is not always possible, but we will try to keep versions consistent\n        to avoid conflicts with gems and javascript plugins."
                }), React.createElement(ChapterBox.make, {
                  number: "1.2",
                  title: "Versions we use in the book.",
                  children: React.createElement("ul", {
                        className: "px-5 list-disc"
                      }, React.createElement("li", undefined, Version.rubyFull), React.createElement("li", undefined, Version.railsFull), React.createElement("li", undefined, Version.nodeFull), React.createElement("li", undefined, Version.yarnFull), React.createElement("li", undefined, Version.rescriptFull))
                }));
}

var Section;

var Title;

var P;

var Box;

var Link;

var make = SectionDevEnv;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  make ,
}
/* react Not a pure module */
