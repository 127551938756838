// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterLink from "../../../../chapters/components/ChapterLink.res.js";
import * as ForwardCode from "../components/ForwardCode.res.js";
import * as ChapterSection from "../../../../chapters/components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../../../chapters/components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../../../chapters/components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../../../chapters/components/ChapterSectionTitle.res.js";

function SectionForward(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Forward"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Over a decade ago, I read ", React.createElement(ChapterLink.make, {
                      href: "https://www.learnenough.com/ruby-on-rails-7th-edition-tutorial",
                      text: "Ruby on Rails Tutorial"
                    }), " by Michael Hartl, which is now in its 7th edition. It was the first time\n        I truly saw the power of programming. Programming with Ruby is a great\n        way to combine creativity, design, and mathematics. The Rails language\n        has inspired thousands of people to take up software engineering and deliver\n        value to the world."), React.createElement(ChapterParagraph.make, {
                  children: "I've since completed the tutorial multiple times, and each time I learned something new.\n        The knowledge I gained helped me launch a business that eventually\n        led me to a full-time career in the software industry. I couldn't be more\n        grateful to the people, including Hartl, who took time to share their\n        experience with others."
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Now, I'm ready to make a small contribution to the community with this book. I hope this\n        it will help you accomplish your goals and deliver amazing\n        products. Thank you for checking it out! Please feel free to share\n        your feedback and ideas with me at ", React.createElement(ChapterLink.make, {
                      href: "mailto:mike@gobotdo.com",
                      text: "mike@gobotdo.com"
                    }), "."), React.createElement(ChapterSectionTitle.make, {
                  text: "Acknowledgements",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "There are many people to thank, without whom this book would not have been possible.\n        First and foremost my wife Jenn, who has always believed in me, along with the many (crazy)\n        ideas I've come up with over the years. I'm also the proud father of two children,\n        Layla and Jack, who inspire me everyday."
                }), React.createElement(ChapterParagraph.make, {
                  children: "When I first came to Colorado, I was introduced to the startup industry by a dear\n        friend of mine, Brian Inderwies. He later became my first software business partner / co-dreamer\n        / creator. Others in the community who have supported my endeavors include: Marc Spritzer,\n        Tom Higley, Tom Spellios, Nicole Gravagna, Chris Olsen, Timi Aguilar, Peter Adams,\n        Allan Wintersieck, Jason Collins, Scott Smeester, and many more."
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "As my first business was winding down, I was fortunate to be connected to ", React.createElement(ChapterLink.make, {
                      href: "https://www.datacenters.com",
                      text: "Datacenters.com "
                    }), "where I'm currently the Chief Technology Officer. Thank you to Joel St. Germain, founder and CEO,\n        for his trust and partnership. I'm also grateful to the current and former team\n        at Datacenters.com, including Heidi Humphreys, Mike Allen, Bob West, April Armijo and\n        the others who have made our continued success possible."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "This book certainly wouldn't be possible without the relentless open-source work done\n        by the team at ", React.createElement(ChapterLink.make, {
                      href: "https://www.shakacode.com",
                      text: "Shakacode"
                    }), " founded by Justin Gordon. Justin has been a mentor to me and many others in the\n        software industry. The team at Shakacode, including Justin, Alex Fedoseev, Juda Meek,\n        Sergey Tarasov, Renan Aguiar and dozens of others build and maintain key open-source\n        software used in this book, namely Ruby on Rails, React on Rails, Shakapacker (formerly Webpacker),\n        ReScript, and more recently the Control Plane Flow gem."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Thank you as well to the team at ", React.createElement(ChapterLink.make, {
                      href: "http://www.tailwindcss.com",
                      text: "TailwindCSS "
                    }), "for building an amazing CSS framework. TailwindCSS has been a game-changer for\n        the industry and helped many people get their ideas off the ground.\n        I strongly encourage you to consider getting a license to ", React.createElement(ChapterLink.make, {
                      href: "https://tailwindui.com/",
                      text: "Tailwind UI"
                    }), " which has many pre-built components to get your project moving ASAP."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "This book is dedicated to my family, friends, and the community that have supported me\n        over the years. In loving memory of ", React.createElement(ChapterLink.make, {
                      href: "http://www.bridgehousebrats.com/about-us.html",
                      text: "Cathy Lee "
                    }), "who inspired generations of kids from a tiny town in Upstate New York to dream big\n        and be kind to others."), React.createElement(ChapterSectionTitle.make, {
                  text: "About the Author",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, React.createElement(ChapterLink.make, {
                      href: "https://www.linkedin.com/in/michaeljohnprice/",
                      text: "Michael Price"
                    }), " is the Chief Technology Officer at ", React.createElement(ChapterLink.make, {
                      href: "https://www.datacenters.com/",
                      text: "Datacenters.com"
                    }), ". ", "He is the proud graduate of ", React.createElement(ChapterLink.make, {
                      href: "https://www.mvcc.edu/",
                      text: "Mohawk Valley Community College"
                    }), " and ", React.createElement(ChapterLink.make, {
                      href: "https://www.syracuse.edu",
                      text: "Syracuse University"
                    }), " where he recieved degrees in Illustration and Political Science.\n        Michael lives in Colorado with his wife and two children."), React.createElement(ChapterSectionTitle.make, {
                  text: "Copyright",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "ReScript React on Rails Tutorial: A detailed guide to advanced programming in Rails with ReScript.\n        Copyright © 2021 - 2024 Michael Price."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "License",
                  titleType: "Sub"
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Markdown",
                  children: ForwardCode.license
                }));
}

var Section;

var Title;

var P;

var Box;

var Link;

var Code;

var make = SectionForward;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  Code ,
  make ,
}
/* react Not a pure module */
