// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Version from "../../../models/Version.res.js";

var versions = "$ ruby -v\n  ruby " + Version.ruby + " (...) [...]\n$ node -v\n  v" + Version.node + "\n$ yarn -v\n  " + Version.yarn;

var gemInstall = "$ gem install rails -v " + Version.rails + "\n$ gem install bundler -v " + Version.bundler + "\n$ bundler -v\n  Bundler version " + Version.bundler + "\n$ rails _" + Version.rails + "_ new rescript_react_on_rails --skip-bundle --skip-javascript --database=postgresql\n$ cd rescript_react_on_rails\n$ git add .\n$ git commit -m \"First commit\"";

var gemfile1 = "# /Gemfile\n\nsource \"https://rubygems.org\"\ngit_source(:github) { |repo| \"https://github.com/#{repo}.git\" }\n\nruby \"" + Version.ruby + "\"\n\ngem \"rails\",            \"" + Version.rails + "\"\ngem \"sprockets-rails\",  \"" + Version.Gemfile.sproketsRails + "\"\ngem \"pg\",               \"" + Version.Gemfile.pg + "\"\ngem \"puma\",             \"" + Version.Gemfile.puma + "\"\ngem \"jbuilder\",         \"" + Version.Gemfile.jbuilder + "\"\ngem \"bootsnap\",         \"" + Version.Gemfile.bootsnap + "\", require: false\ngem \"tzinfo-data\", platforms: %i[ mingw mswin x64_mingw jruby ]\ngem \"cssbundling-rails\", \"" + Version.Gemfile.cssBundlingRails + "\"\n\ngroup :development, :test do\n  gem \"debug\",          \"" + Version.Gemfile.debug + "\", platforms: %i[ mri mingw x64_mingw ]\nend\n\ngroup :development do\n  gem \"web-console\",    \"" + Version.Gemfile.webConsole + "\"\nend\n\ngroup :test do\n  gem \"capybara\",           \"" + Version.Gemfile.capybara + "\"\n  gem \"selenium-webdriver\", \"" + Version.Gemfile.seleniumWebdriver + "\"\nend";

var bundleInstall = "$ bundle _" + Version.bundler + "_ install\n$ rails db:setup\n$ rails db:migrate\n$ rails server";

var addShakapacker = "$ bundle _" + Version.bundler + "_ add shakapacker --version=" + Version.Gemfile.shakapacker + " --strict\n$ rails _" + Version.rails + "_ shakapacker:install\n    create  config/shakapacker.yml\n    conflict  package.json\nOverwrite /path/to/rescript_react_on_rails/package.json? (enter \"h\" for help) [Ynaqdhm] y\n. installing...\n.\n.\nYN0000: Done with warnings in 2s 601ms\n$ bundle _" + Version.bundler + "_ add react_on_rails --version=" + Version.Gemfile.reactOnRails + " --strict";

var gemfile15 = "# /Gemfile\n\nsource \"https://rubygems.org\"\ngit_source(:github) { |repo| \"https://github.com/#{repo}.git\" }\n\n.\n.\n.\n\n# Shakapacker - See https://github.com/shakacode/shakapacker\ngem \"shakapacker\", \"= " + Version.Gemfile.shakapacker + "\"\n# React on Rails - See https://github.com/shakacode/react_on_rails\ngem \"react_on_rails\", \"= " + Version.Gemfile.reactOnRails + "\"";

var generateReactOnRails = "$ rails _" + Version.rails + "_ generate react_on_rails:install\n. installing...\n.\n.\nOverwrite /path/to/rescript_react_on_rails/config/webpack/webpack.config.js? (enter \"h\" for help) [Ynaqdhm] y\n       force  config/webpack/webpack.config.js\n      create  config/webpack/webpackConfig.js\nAdding Shakapacker v7 config file\n    conflict  config/shakapacker.yml\nOverwrite /path/to/rescript_react_on_rails/config/shakapacker.yml? (enter \"h\" for help) [Ynaqdhm] y\n       force  config/shakapacker.yml\n.\n.\n.";

var updatePackageJson = "{\n  \"name\": \"rescript_react_on_rails\",\n  \"packageManager\": \"yarn@" + Version.yarn + "\",\n  .\n  .\n  .\n}";

var mkdir = "$ mkdir rescript_rails && cd rescript_rails";

var yarnInit = "$ yarn init\n$ touch .yarnrc.yml";

var updateYarnRc = "# /.yarnrc.yml\n\nnodeLinker: node-modules\n";

var commitYarnRc = "$ yarn install\n$ git add .\n$ git commit -m \"Initialize Yarn\"";

var commitBasicRails = "$ git add .\n$ git commit -m \"Update Gemfile\"";

var pagesController = "$ touch app/controllers/pages_controller.rb";

var pagesControllerCode = "# /app/controllers/pages_controller.rb\n\nclass PagesController < ApplicationController\n\n  def hello_world; end\nend";

var updatedHelloWorldJsx = "// /app/javascript/bundles/HelloWorld/components/HelloWorld.jsx\n\nimport PropTypes from 'prop-types';\nimport React, { useState } from 'react';\nimport * as style from './HelloWorld.module.css'; // New line\n\nconst HelloWorld = (props) => {\n  const [name, setName] = useState(props.name);\n\n  return (\n    <div>\n      <h3>Hello, {name}!</h3>\n      <hr />\n      <form>\n        <label className={style.bright} htmlFor=\"name\">\n          Say hello to:\n          <input id=\"name\" type=\"text\" value={name} onChange={(e) => setName(e.target.value)} />\n        </label>\n      </form>\n    </div>\n  );\n};\n\nHelloWorld.propTypes = {\n  name: PropTypes.string.isRequired, // this is passed from the Rails view\n};\n\nexport default HelloWorld;\n";

var helloWorldCreate = "$ mkdir app/views/pages\n$ touch app/views/pages/hello_world.html.erb";

var helloWorldCode = "<!-- /app/views/pages/hello_world.html.erb -->\n\n<div>Hello World!</div>";

var helloRoutes = "# /app/config/routes.rb\n\nRails.application.routes.draw do\n  # Define your application routes per the DSL in https://guides.rubyonrails.org/routing.html\n\n  # Reveal health status on /up that returns 200 if the app boots with no exceptions, otherwise 500.\n  # Can be used by load balancers and uptime monitors to verify that the app is live.\n  get \"up\" => \"rails/health#show\", as: :rails_health_check\n\n  # Defines the root path route (\"/\")\n  root \"pages#hello_world\"\nend";

var commitHelloWorld = "$ git add .\n$ git commit -m \"Add basic hello world page.\"";

var shakapackerError = "ERROR: You have uncommitted code. Please commit or stash your changes before continuing\nERROR: react_on_rails generator prerequisites not met!";

var commitShakapacker = "$ yarn\n$ git add .\n$ git commit -m \"Add React on Rails and Shakapacker\"";

var overwriteWebpack = "Overwrite PATH/config/webpack/webpack.config.js? (enter \"h\" for help) [Ynaqdhm] y\nOverwrite PATH/config/webpacker.yml? (enter \"h\" for help) [Ynaqdhm] y";

var reactNextSteps = "We did not find a spec/rails_helper.rb or spec/spec_helper.rb to add\nthe React on Rails Test helper, which ensures that if we are running\njs tests, then we are using latest webpack assets. You can later add\nthis to your rspec config:\n\n# This will use the defaults of :js and :server_rendering meta tags\nReactOnRails::TestHelper.configure_rspec_to_compile_assets(config)\n\n\nWhat to do next:\n\n  - See the documentation on https://github.com/shakacode/shakapacker#webpack-configuration\n    for how to customize the default webpack configuration.\n\n  - Include your webpack assets to your application layout.\n\n      <%= javascript_pack_tag 'hello-world-bundle' %>\n\n  - To start Rails server run:\n\n      ./bin/dev # Running with HMR\n\n    or\n\n      ./bin/dev-static # Running with statically created bundles, without HMR\n\n  - To server render, change this line app/views/hello_world/index.html.erb to\n    \`prerender: true\` to see server rendering (right click on page and select \"view source\").\n\n      <%= react_component(\"HelloWorldApp\", props: @hello_world_props, prerender: true) %>\n\nAlternative steps to run the app:\n\n  - Run \`rails s\` to start the Rails server.\n\n  - Run bin/webpacker-dev-server to start the Webpack dev server for compilation of Webpack\n    assets as soon as you save. This default setup with the dev server does not work\n    for server rendering\n\n  - Visit http://localhost:3000/hello_world and see your React On Rails app running!\n\n  - To turn on HMR, edit config/webpacker.yml and set HMR to true. Restart the rails server\n    and bin/webpacker-dev-server. Or use Procfile.dev.";

var removeHelloWorld = "$ rm app/controllers/hello_world_controller.rb\n$ rm app/views/layouts/hello_world.html.erb\n$ rm -rf app/views/hello_world\n$ rm app/javascript/packs/hello-world-bundle.js";

var updateApplicationJs = "// app/javascript/packs/application.js\n\nimport ReactOnRails from 'react-on-rails';\n\nimport HelloWorld from '../bundles/HelloWorld/components/HelloWorld';\n\n// This is how react_on_rails can see the HelloWorld in the browser.\nReactOnRails.register({\n  HelloWorld,\n});";

var helloWorldReactView = "# app/views/pages/hello_world.html.erb\n\n<%=\n  react_component(\n    \"HelloWorld\", props: @hello_world_props, prerender: false\n  )\n%>";

var updatedPagesController = "# app/controllers/pages_controller.rb\n\nclass PagesController < ApplicationController\n  def hello_world\n    @hello_world_props = { name: \"Everyone\" }\n  end\nend";

var updateProcfile = "# Procfile for development using HMR\n# You can run these commands in separate shells\nrails: bundle exec rails s -p 3000\nwp-client: bin/webpacker-dev-server\nwp-server: SERVER_BUNDLE_ONLY=yes bin/webpacker --watch";

var startForeman = "$ foreman start -f Procfile.dev";

var ignoreYarn = "# /.gitignore\n.\n.\n.\n/node_modules\n/.yarn/install-state.gz";

var updateGitIgnore = "# /.gitignore\n.\n.\n.\n.yarn/cache/*\n*.res.js\n.merlin\n.bsb.lock\n/lib/bs/\n\n/app/assets/builds/*\n!/app/assets/builds/.keep\n*DS_Store";

var commitHelloReact = "$ git add .\n$ git commit -m \"Basic React on Rails app with Hello World.\"";

export {
  mkdir ,
  versions ,
  gemInstall ,
  gemfile1 ,
  bundleInstall ,
  yarnInit ,
  updateYarnRc ,
  commitYarnRc ,
  commitBasicRails ,
  pagesController ,
  pagesControllerCode ,
  updatedHelloWorldJsx ,
  helloWorldCreate ,
  helloWorldCode ,
  helloRoutes ,
  commitHelloWorld ,
  addShakapacker ,
  gemfile15 ,
  shakapackerError ,
  commitShakapacker ,
  generateReactOnRails ,
  overwriteWebpack ,
  reactNextSteps ,
  removeHelloWorld ,
  updateApplicationJs ,
  helloWorldReactView ,
  updatedPagesController ,
  updateProcfile ,
  startForeman ,
  ignoreYarn ,
  updateGitIgnore ,
  updatePackageJson ,
  commitHelloReact ,
}
/* No side effect */
