// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter04Listings from "../components/Chapter04Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionGvc(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "What is the Global Virtual Cloud (GVC)?"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, React.createElement(ChapterLink.make, {
                      href: "https://docs.controlplane.com/concepts/gvc",
                      text: "Control Plane "
                    }), "describes the Global Virtual Cloud (GVC) as \"a set of cloud providers and their locations.\"\n        By default, all regions (locations) available from AWS, GCP and Microsoft Azure are available\n        for selection. Imagine a GVC as a unified cloud that orchestrates multiple cloud services with\n        a few clicks."), React.createElement(ChapterSectionTitle.make, {
                  text: "How does it work?",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Control Plane organizes its cloud services in the following basic heirarchy: ", React.createElement(ChapterFile.make, {
                      text: "Organization > GVCs > Workloads"
                    }), ". ", "Each organization can have multiple GVCs depending on how you want to structure your deployments\n        and each GVC can have its own set of locations with one or more Workloads."), React.createElement(ChapterParagraph.make, {
                  children: "How you organize deployments is entirely up to you. We'll do a basic setup that's\n        is ideally suited for test environments. In production, you'll likely need replicas and\n        external connections to 3rd party services like Amazon RDS. Here, we'll spin up a PostgreSQL\n        database and Redis instances inside individual Workloads."
                }), React.createElement(ChapterParagraph.make, {
                  children: "Our workloads will automatically scale to zero when not in use so that you're not consuming\n        services when no traffic is being served."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "The Cloud Conductor",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Once the work of setting up the Organization, GVC and Workloads is done, Control Plane takes care\n        of the rest. You simply build an image of your application using Docker, push it to Control Plane,\n        and deploy the image to your Workloads. Control Plane hosts your image so you don't have to.\n        Control Plane then spins up new replicas and routes traffic to the new deployment when it's ready."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Routing Traffic",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Control Plane automatically routes traffic to the nearest location defined in your GVC. Each location\n        has its own instance and the end user gets the fastest response time possible. When multiple replicas\n        are running and one goes down, Control Plane automatically routes traffic to the next available replica."
                }), React.createElement(ChapterParagraph.make, {
                  children: "Over the years, we've all seen what happens when one of the major cloud providers has an outtage.\n        Some of our favorite services have experienced this. Or, maybe you have woken up\n        to your own company's application being down. With Control Plane, you can have two locations on the US east coast -\n        one on GCP and the other on AWS. If GCP goes down, no worries, Control Plane routes traffic to AWS."
                }), React.createElement(ChapterBox.make, {
                  number: Chapter04Listings.Box.controlPlaneBenefits.number,
                  title: Chapter04Listings.Box.controlPlaneBenefits.text,
                  children: null
                }, React.createElement("p", undefined, "Here are a few benefits of Control Plan and why we switched:"), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "Leverage Capacity AI to reduce cloud costs by 60%-80%."), React.createElement("li", undefined, "Deploy anywhere cloud is avaiable, or BYOK (Bring Your Own Kubernetes)."), React.createElement("li", undefined, "Achieve cloud maturity with 99.999% uptime."), React.createElement("li", undefined, "Reduce DevOps overhead and spend more building products than troubleshooting them."), React.createElement("li", undefined, "Turn your colocation into a cloud."))), React.createElement(ChapterSectionTitle.make, {
                  text: "What to know more?",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "If you're interested in diving deeper into Control Plane, you can schedule a demo at ", React.createElement(ChapterLink.make, {
                      href: "https://www.datacenters.com/services/multicloud",
                      text: "Datacenters.com"
                    }), ". ", "Shameless plug, I know, but it's a great product, and the team can help you with\n        large-scale, global deployments while migrating to the GVC."));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var Listings;

var make = SectionGvc;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  Listings ,
  make ,
}
/* react Not a pure module */
