// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SectionRescriptIntro from "./sections/SectionRescriptIntro.res.js";
import * as SectionRescriptInstallation from "./sections/SectionRescriptInstallation.res.js";

function Chapter02(props) {
  var setScroll = props.setScroll;
  return React.createElement(React.Fragment, {}, React.createElement(SectionRescriptIntro.make, {}), React.createElement(SectionRescriptInstallation.make, {
                  onClick: (function ($$event) {
                      setScroll($$event, "RescriptHelloWorld");
                    })
                }));
}

var make = Chapter02;

export {
  make ,
}
/* react Not a pure module */
