// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Faker from "@faker-js/faker";

var Line = {};

var Paragraph = {};

var Sentence = {};

var Slug = {};

var $$Text = {};

function strategyToString(strategyType) {
  switch (strategyType) {
    case "AnyLength" :
        return "any-length";
    case "Closest" :
        return "closest";
    case "Fail" :
        return "fail";
    case "Longest" :
        return "longest";
    case "Shortest" :
        return "shortest";
    
  }
}

var Word = {
  strategyToString: strategyToString
};

function lines(count) {
  return Faker.faker.lorem.lines(count);
}

function linesMinMax(lineCount) {
  return Faker.faker.lorem.lines(lineCount);
}

function paragraph(count) {
  return Faker.faker.lorem.paragraph(count);
}

function paragraphMinMax(sentenceCount) {
  return Faker.faker.lorem.paragraph(sentenceCount);
}

function paragraphs(count, separator) {
  return Faker.faker.lorem.paragraphs(count, separator);
}

function paragraphsMinMax(pargraphCount, separator) {
  return Faker.faker.lorem.paragraphs(pargraphCount, separator);
}

function sentence(count) {
  return Faker.faker.lorem.sentence(count);
}

function sentenceMinMax(wordCount) {
  return Faker.faker.lorem.sentence(wordCount);
}

function sentences(count, separator) {
  return Faker.faker.lorem.sentences(count, separator);
}

function sentencesMinMax(sentenceCount, separator) {
  return Faker.faker.lorem.sentences(sentenceCount, separator);
}

function slug(count) {
  return Faker.faker.lorem.slug(count);
}

function slugMinMax(wordCount) {
  return Faker.faker.lorem.slug(wordCount);
}

var text = Faker.faker.lorem.text();

function word(count) {
  return Faker.faker.lorem.word(count);
}

function wordStrategy(strategy) {
  return Faker.faker.lorem.word({
              strategy: strategyToString(strategy)
            });
}

function wordFull(length, strategy) {
  return Faker.faker.lorem.word({
              length: length,
              strategy: strategyToString(strategy)
            });
}

function words(count) {
  return Faker.faker.lorem.words(count);
}

function wordsMinMax(wordCount) {
  return Faker.faker.lorem.words(wordCount);
}

export {
  Line ,
  Paragraph ,
  Sentence ,
  Slug ,
  $$Text ,
  Word ,
  lines ,
  linesMinMax ,
  paragraph ,
  paragraphMinMax ,
  paragraphs ,
  paragraphsMinMax ,
  sentence ,
  sentenceMinMax ,
  sentences ,
  sentencesMinMax ,
  slug ,
  slugMinMax ,
  text ,
  word ,
  wordStrategy ,
  wordFull ,
  words ,
  wordsMinMax ,
}
/* text Not a pure module */
