// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Info from "../../../styleguide/icons/Info.res.js";
import * as React from "react";
import * as Routes from "../../../routes/Routes.res.js";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter04Code from "../components/Chapter04Code.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ReactTooltip from "react-tooltip";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter04Listings from "../components/Chapter04Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionCplnFlow(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Install Control Plane Flow Gem"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "The ", React.createElement(ChapterLink.make, {
                      href: Routes.ControlPlane.Flow.gem,
                      text: "Control Plane Flow gem "
                    }), "was initially created to help developers migrate from using Heroku\n        to Control Plane. It's a relatively new gem and should be noted\n        that it's under very active development with frequent releases.\n        Most recently, the gem was renamed from ", React.createElement(ChapterFile.make, {
                      text: "cpl"
                    }), " to ", React.createElement(ChapterFile.make, {
                      text: "cpflow"
                    }), "."), React.createElement(ChapterSectionTitle.make, {
                  text: "Install Control Plane Flow",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Start by adding the ", React.createElement(ChapterFile.make, {
                      text: "cpflow"
                    }), " ", "gem to your Gemfile. Before running the bundle command,\n        update debug if needed - see below. An update will come soon to cpflow."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowInstall
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowGemfile)),
                  language: "Ruby",
                  children: Chapter04Code.cpflowGemfile
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Cplflow Commands",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "As you can see here, the gem commands parallel cpln commands, with a few\n        additions to help with templating and other common tasks."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowHelp)),
                  language: "Console",
                  children: Chapter04Code.cpflowHelp
                }), React.createElement(ChapterParagraph.make, {
                  children: "We'll dive deeper into some of these commands to get our app ready for deployment."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Create Deployment Files",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Control Plane Flow leverages a templating system to help you deploy your app. The files\n        are organized in a the ", React.createElement(ChapterFile.make, {
                      text: ".controlplane"
                    }), " ", "folder at the root of your project. Technically, your stack doesn't need to use Ruby\n        or Rails. You can use the gem with your preferred stack."), React.createElement(ChapterSectionTitle.make, {
                  text: "Create the Dockerfile",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "We need to create a series of files to help Control Plane Flow understand how to deploy\n        our app. The first file we'll create is the ", React.createElement(ChapterFile.make, {
                      text: "Dockerfile"
                    }), "."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowAddDockerFile
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowDockerFile)),
                  language: "Docker",
                  children: Chapter04Code.cpflowDockerFile
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Add the GVC Template",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Next, create templates for the GVC and the Workloads we'll deploy."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowTemplates
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowGvc)),
                  language: "YAML",
                  children: Chapter04Code.cpflowGvc
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Add the PostgreSQL Template(s)",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "The PostgreSQL template is a bit more involved because we need to set up several templates\n        in one."
                }), React.createElement(ChapterBox.make, {
                  number: Chapter04Listings.Box.postgresTemplates.number,
                  title: Chapter04Listings.Box.postgresTemplates.text,
                  children: React.createElement("ol", {
                        className: ChapterStyles.Box.numberedList
                      }, React.createElement("li", undefined, "A Volumeset to store the data."), React.createElement("li", undefined, "Secrets to access the database."), React.createElement("li", undefined, "A Workload Identity for the database. Read More - ", React.createElement(ChapterLink.make, {
                                href: Routes.ControlPlane.Docs.identities,
                                text: "Identities"
                              }), "."), React.createElement("li", undefined, "A Policy that allows the postgres workload to access secrets."), React.createElement("li", undefined, "Finally, the PostgreSQL Workload itself."))
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowPostgres)),
                  language: "YAML",
                  children: Chapter04Code.cpflowPostgres
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "The Rails Workload Template",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "The Rails template is a bit simpler than the PostgreSQL template, but it's where\n        all the traffic will be served. Note that only one workload within a GVC can serve\n        traffic."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowRails)),
                  language: "YAML",
                  children: Chapter04Code.cpflowRails
                }), React.createElement(ChapterBox.make, {
                  number: Chapter04Listings.Box.autoscaling.number,
                  title: Chapter04Listings.Box.autoscaling.text,
                  children: null
                }, React.createElement("div", undefined, "Scaling is determined based on a specific metric."), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "\"disabled\" means there's only one \"Replica count\". In the API,\n            the Min Scale and Max Scale are both set to the same specified value."), React.createElement("li", undefined, "\"concurrency (Concurrent Requests)\" uses the number of\n            concurrent requests to the workload."), React.createElement("li", undefined, "\"cpu (CPU Utilization)\" uses % processor time utilized by the workload."), React.createElement("li", undefined, "\"rps (Requests Per Second)\" uses requests per second for the target.")), React.createElement("div", undefined, "The number of replicas for this deployment scales up/down in accordance\n          with the chosen scaling strategy and values provided."), React.createElement("div", {
                      className: "flex justify-end"
                    }, React.createElement("a", {
                          className: "controlPlaneAutoscaling"
                        }, React.createElement(Info.make, {})), React.createElement(ReactTooltip.Tooltip, {
                          className: "bg-white",
                          variant: "info",
                          clickable: true,
                          anchorSelect: ".controlPlaneAutoscaling",
                          place: "left",
                          children: null
                        }, "Autoscaling. ", React.createElement("a", {
                              className: "font-medium hover:text-purple-600",
                              href: Routes.ControlPlane.Docs.autoScaling,
                              target: "_blank"
                            }, "Control Plane Documentation")))), React.createElement(ChapterSectionTitle.make, {
                  text: "The Redis Workload Template",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "For basic caching and background jobs, a Redis workload can be configured."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowRedis)),
                  language: "YAML",
                  children: Chapter04Code.cpflowRedis
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Final Deployment Setup Steps",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "We're now ready to configure the ", React.createElement(ChapterFile.make, {
                      text: "controlplane.yml"
                    }), " ", "and ", React.createElement(ChapterFile.make, {
                      text: "release_script.sh"
                    }), " ", "files. This file is where we'll define some higher level settings."), React.createElement(ChapterSectionTitle.make, {
                  text: "Create the YAML File",
                  titleType: "Mini"
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowCreateCplnYml
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowCplnYml)),
                  language: "YAML",
                  children: Chapter04Code.cpflowCplnYml
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Release Script",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "A basic release script, which includes a command to create and migrate the database."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowCreateReleaseScript
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowReleaseScript)),
                  language: "Bash",
                  children: Chapter04Code.cpflowReleaseScript
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Add the Entrypoint File",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: "To bring it all together, we need an entrypoint to fire up the servers."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowCreateEntrypoint
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cpflowEntrypoint)),
                  language: "Bash",
                  children: Chapter04Code.cpflowEntrypoint
                }));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var $$Image;

var Code;

var Listings;

var make = SectionCplnFlow;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  $$Image ,
  Code ,
  Listings ,
  make ,
}
/* Info Not a pure module */
