// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DesignsNavigation from "../navigation/DesignsNavigation.res.js";
import * as DesignsLayoutStyle from "./DesignsLayoutStyle.res.js";

function DesignsLayout(props) {
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Dashboard";
  return React.createElement("div", undefined, React.createElement(DesignsNavigation.make, {
                  currentLink: currentLink
                }), React.createElement("div", {
                  className: DesignsLayoutStyle.contentContainer
                }, Belt_Option.getExn(props.children)));
}

var Css;

var make = DesignsLayout;

export {
  Css ,
  make ,
}
/* react Not a pure module */
