// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter01Code from "../components/Chapter01Code.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter01Listings from "../components/Chapter01Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Hello_world_reactPng from "../images/hello_world_react.png";
import Hello_world_react_typingPng from "../images/hello_world_react_typing.png";

var helloWorldReact = Hello_world_reactPng;

var helloWorldReactTyping = Hello_world_react_typingPng;

function SectionPureReactHelloWorld(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Pure React Hello World"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Phew, glad the history lesson is over. You are now ready to integrate your\n        Rails application with React. We can start by following the steps outlined\n        by the ", React.createElement(ChapterLink.make, {
                      href: "https://www.shakacode.com/react-on-rails/docs/getting-started/",
                      text: "React on Rails Documentation"
                    }), "."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.addShakapacker
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.shakaGemfile)),
                  language: "Ruby",
                  children: Chapter01Code.gemfile15
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.gitignore)),
                  language: "Dockerfile",
                  children: Chapter01Code.updateGitIgnore
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.packageJson)),
                  language: "JSON",
                  children: Chapter01Code.updatePackageJson
                }), React.createElement(ChapterParagraph.make, {
                  children: "Before moving forward, be sure to commit all your changes before running\n        the next command, otherwise an error message will appear:"
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.shakapackerError
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.commitShakapacker
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.generateReactOnRails
                }), React.createElement(ChapterParagraph.make, {
                  children: "Enter \"y\" to overwrite shakapacker configs."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.overwriteWebpack
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "At the end of running the react_on_rails installation,\n        you'll see the following message, which we will address now. ", React.createElement("strong", undefined, "Do not follow these instructions explicitly.")), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.reactNextSteps
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Since we're not following these instructions, if you attempted to spin up the\n        Rails server, you would get an error message. Let's get that fixed.\n        First, delete the autogenerated ", React.createElement(ChapterFile.make, {
                      text: "hello_world_controller.rb"
                    }), ", ", React.createElement(ChapterFile.make, {
                      text: "hello_world.html.erb"
                    }), "and ", React.createElement(ChapterFile.make, {
                      text: "hello-world-bundle.js"
                    }), " . The main ", React.createElement(ChapterFile.make, {
                      text: "application.js"
                    }), " and ", React.createElement(ChapterFile.make, {
                      text: "server-bundle.js"
                    }), " ", "files will be used instead."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.removeHelloWorld
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Next, update ", React.createElement(ChapterFile.make, {
                      text: "application.js"
                    }), " with the following code."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.applicationJs)),
                  language: "JavaScript",
                  children: Chapter01Code.updateApplicationJs
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.routesAsIs)),
                  language: "Ruby",
                  children: Chapter01Code.helloRoutes
                }), React.createElement(ChapterParagraph.make, {
                  children: "Update our \"Hello World\" view with the following content."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.helloWorldHtml)),
                  language: "ERB",
                  children: Chapter01Code.helloWorldReactView
                }), React.createElement(ChapterParagraph.make, {
                  children: "Finally, update our pages controller with the props for the\n        HelloWorld component."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.pagesController)),
                  language: "Ruby",
                  children: Chapter01Code.updatedPagesController
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "As of this writing, there is a minor bug in the React on Rails template that's\n        generated. To fix the bug, update the ", React.createElement(ChapterFile.make, {
                      text: "HelloWorld.jsx"
                    }), " file with the following new line. "), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.updatedHelloWorldJsx)),
                  language: "JavaScript",
                  children: Chapter01Code.updatedHelloWorldJsx
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Install formeman with ", React.createElement(ChapterFile.make, {
                      text: "$ gem install foreman"
                    }), ", so we can start the HMR server. The run the following command."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.startForeman
                }), React.createElement(ChapterImage.make, {
                  id: "railsDefault",
                  caption: "Figure 1.4: A basic Hello World React page!",
                  onClick: props.onClick,
                  imageType: "Preview",
                  image: helloWorldReact
                }), React.createElement(ChapterParagraph.make, {
                  children: "Go head and put your name in the text field and watch as the\n        magic of React updates the view!"
                }), React.createElement(ChapterImage.make, {
                  id: "typeHelloReact",
                  caption: "Figure 1.5: The view updates as you type.",
                  imageType: "Static",
                  image: helloWorldReactTyping
                }), React.createElement(ChapterParagraph.make, {
                  children: "We now have a working React on Rails app. Coming up, we'll\n        convert our React app into to a ReScript React on Rails application.\n        Be sure to commit your changes before moving on."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.commitHelloReact
                }));
}

var Section;

var Title;

var P;

var Link;

var Code;

var Listings;

var $$File;

var make = SectionPureReactHelloWorld;

export {
  helloWorldReact ,
  helloWorldReactTyping ,
  Section ,
  Title ,
  P ,
  Link ,
  Code ,
  Listings ,
  $$File ,
  make ,
}
/* helloWorldReact Not a pure module */
