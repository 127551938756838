// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterPreview from "../../components/ChapterPreview.res.js";
import * as PreviewNewHomeColorStyles from "./PreviewNewHomeColorStyles.res.js";

function PreviewNewHomeColor(props) {
  return React.createElement(ChapterPreview.make, {
              children: React.createElement("div", {
                    className: PreviewNewHomeColorStyles.container
                  }, React.createElement("div", {
                        className: PreviewNewHomeColorStyles.glowLeft
                      }), React.createElement("div", {
                        className: PreviewNewHomeColorStyles.glowMiddle
                      }), React.createElement("div", {
                        className: PreviewNewHomeColorStyles.glowRight
                      }), React.createElement("div", {
                        className: PreviewNewHomeColorStyles.typeIt
                      }, "ReScript React on Rails"), React.createElement("div", {
                        className: PreviewNewHomeColorStyles.subTitle
                      }, "I'm now a ReScript on Rails developer!"))
            });
}

var Css;

var make = PreviewNewHomeColor;

export {
  Css ,
  make ,
}
/* react Not a pure module */
