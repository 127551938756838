// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var semibold = "font-semibold";

var h1 = Cx.cx([
      "text-5xl",
      semibold
    ]);

var h2 = Cx.cx([
      "text-4xl",
      semibold
    ]);

var h3 = Cx.cx([
      "text-3xl",
      semibold
    ]);

var h4 = Cx.cx([
      "text-2xl",
      semibold
    ]);

var h2$1 = Cx.cx([
      h2,
      "text-blue-gray-500"
    ]);

var Section = {
  h2: h2$1
};

export {
  semibold ,
  h1 ,
  h2 ,
  h3 ,
  h4 ,
  Section ,
}
/* h1 Not a pure module */
