// Generated by ReScript, PLEASE EDIT WITH CARE


var Designs = {
  index: "/admin/designs",
  palette: "/admin/designs/palette",
  types: "/admin/designs/types"
};

var Sessions = {
  $$new: "/users/sign_in"
};

var Users = {
  Sessions: Sessions
};

function show(number) {
  return "/chapters/" + String(number);
}

var Chapter = {
  forward: "/chapters/forward",
  show: show
};

var Author = {
  linkedin: "https://www.linkedin.com/in/michaeljohnprice/"
};

var Docker = {
  getStarted: "https://www.docker.com/get-started/",
  desktopOverview: "https://docs.docker.com/desktop/?_gl=1*1gzx82p*_gcl_au*MTgyOTgxODQwNC4xNzE5MDkyMzQw*_ga*MTg4NzIxNzIxNC4xNzA5MzEwNjkw*_ga_XJWPQMJYHQ*MTcxOTA5MjMzOS4xMy4xLjE3MTkwOTIzNDIuNTcuMC4w"
};

var Flow = {
  gem: "https://github.com/shakacode/control-plane-flow"
};

var Docs = {
  cli: "https://docs.controlplane.com/quickstart/quick-start-3-cli",
  cliHomebrew: "https://github.com/controlplane-com/homebrew-cpln",
  identities: "https://docs.controlplane.com/reference/identity#identities",
  autoScaling: "https://docs.controlplane.com/reference/workload#autoscaling"
};

var ControlPlane = {
  Flow: Flow,
  Docs: Docs
};

export {
  Designs ,
  Users ,
  Chapter ,
  Author ,
  Docker ,
  ControlPlane ,
}
/* No side effect */
