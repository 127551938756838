// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter04Listings from "../components/Chapter04Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionCplnSteps(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Control Plane Deployment Steps"
                }), React.createElement(ChapterParagraph.make, {
                  children: "There are several steps we're going to walk through to you started.\n        There are some prerequisites you'll need to have installed on your machine.\n        Here's an overview of the steps we're going to conver:"
                }), React.createElement(ChapterBox.make, {
                  number: Chapter04Listings.Box.controlPlaneSteps.number,
                  title: Chapter04Listings.Box.controlPlaneSteps.text,
                  children: React.createElement("ol", {
                        className: ChapterStyles.Box.numberedList
                      }, React.createElement("li", undefined, "Install Docker Desktop."), React.createElement("li", undefined, "Sign up for Control Plane."), React.createElement("li", undefined, "Create an Organization."), React.createElement("li", undefined, "Install the Control Plane CLI."), React.createElement("li", undefined, "Log into the Control Plane CLI on desktop."), React.createElement("li", undefined, "Log into Control Plane org images."), React.createElement("li", undefined, "Install the Control Plane Flow gem."), React.createElement("li", undefined, "Create a Dockerfile."), React.createElement("li", undefined, "Create deployment templates for GVCs and Workloads."), React.createElement("li", undefined, "Configure the controlplane.yml file."), React.createElement("li", undefined, "Create an entrypoint.sh file."), React.createElement("li", undefined, "Deploy to Control Plane."))
                }));
}

var Section;

var Title;

var P;

var Box;

var Listings;

var make = SectionCplnSteps;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Listings ,
  make ,
}
/* react Not a pure module */
