// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SectionDevEnv from "./sections/SectionDevEnv.res.js";
import * as SectionOverview from "./sections/SectionOverview.res.js";
import * as SectionBasicRails from "./sections/SectionBasicRails.res.js";
import * as SectionHelloWorld from "./sections/SectionHelloWorld.res.js";
import * as SectionGettingStarted from "./sections/SectionGettingStarted.res.js";
import * as SectionHelloWorldReact from "./sections/SectionHelloWorldReact.res.js";
import * as SectionPureReactHelloWorld from "./sections/SectionPureReactHelloWorld.res.js";

function Chapter01(props) {
  var setScroll = props.setScroll;
  return React.createElement(React.Fragment, {}, React.createElement(SectionOverview.make, {}), React.createElement(SectionGettingStarted.make, {}), React.createElement(SectionDevEnv.make, {}), React.createElement(SectionBasicRails.make, {
                  onClick: (function ($$event) {
                      setScroll($$event, "RailsDefault");
                    })
                }), React.createElement(SectionHelloWorld.make, {
                  onClick: (function ($$event) {
                      setScroll($$event, "HelloWorld");
                    })
                }), React.createElement(SectionHelloWorldReact.make, {}), React.createElement(SectionPureReactHelloWorld.make, {
                  onClick: (function ($$event) {
                      setScroll($$event, "HelloWorldReact");
                    })
                }));
}

var make = Chapter01;

export {
  make ,
}
/* react Not a pure module */
