// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function colorToContainerClass(color) {
  if (color === "Blue") {
    return ChapterStyles.Box.blueContainer;
  } else {
    return ChapterStyles.Box.redContainer;
  }
}

function colorToTitleClass(color) {
  if (color === "Blue") {
    return ChapterStyles.Box.blueTitle;
  } else {
    return ChapterStyles.Box.redTitle;
  }
}

function colorToContentsClass(color) {
  if (color === "Blue") {
    return ChapterStyles.Box.blueContents;
  } else {
    return ChapterStyles.Box.redContents;
  }
}

function ChapterBox(props) {
  var __color = props.color;
  var color = __color !== undefined ? __color : "Blue";
  return React.createElement("div", {
              className: colorToContainerClass(color)
            }, React.createElement("div", {
                  className: colorToTitleClass(color)
                }, "Box " + props.number + " - " + props.title), React.createElement("div", {
                  className: colorToContentsClass(color)
                }, props.children));
}

var Css;

var make = ChapterBox;

export {
  Css ,
  colorToContainerClass ,
  colorToTitleClass ,
  colorToContentsClass ,
  make ,
}
/* react Not a pure module */
