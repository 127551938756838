// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var text = Cx.cx([
      "font-semibold",
      "font-mono",
      "text-blue-gray-700"
    ]);

var Style = {
  text: text
};

export {
  Style ,
}
/* text Not a pure module */
