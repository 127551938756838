// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterLink(props) {
  var __blank = props.blank;
  var children = props.children;
  var className = props.className;
  var blank = __blank !== undefined ? __blank : true;
  return React.createElement("a", {
              className: Cx.cx([
                    ChapterStyles.link,
                    className !== undefined ? className : ""
                  ]),
              href: props.href,
              target: blank ? "_blank" : ""
            }, props.text, children !== undefined ? Caml_option.valFromOption(children) : null);
}

var Css;

var make = ChapterLink;

export {
  Css ,
  make ,
}
/* react Not a pure module */
