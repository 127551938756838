// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var section = Cx.cx([
      "flex",
      "flex-col",
      "gap-8",
      "border-b",
      "border-blue-gray-200",
      "pb-8"
    ]);

var examples = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var example = Cx.cx([
      "flex",
      "gap-4"
    ]);

var exampleTitle = "flex-none w-20";

var exampleText = "flex-1";

export {
  section ,
  examples ,
  example ,
  exampleTitle ,
  exampleText ,
}
/* section Not a pure module */
