// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as DesignsContentStyles from "./DesignsContentStyles.res.js";

function DesignsContent(props) {
  return React.createElement("div", {
              className: DesignsContentStyles.container
            }, props.children);
}

var Css;

var make = DesignsContent;

export {
  Css ,
  make ,
}
/* react Not a pure module */
