// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterPreview from "../../components/ChapterPreview.res.js";
import * as PreviewRescriptHelloWorldTailwindStyles from "./PreviewRescriptHelloWorldTailwindStyles.res.js";

function PreviewNewHomeInitial(props) {
  return React.createElement(ChapterPreview.make, {
              children: React.createElement("div", {
                    className: PreviewRescriptHelloWorldTailwindStyles.container
                  }, React.createElement("div", undefined, "New Home Page"))
            });
}

var Css;

var make = PreviewNewHomeInitial;

export {
  Css ,
  make ,
}
/* react Not a pure module */
