// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionTailwindOverview(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Overview"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "While Tailwind CSS (Tailwind) isn't a requirement for styling a ", React.createElement("em", undefined, "ReScript on Rails "), "application, once learned it is a super fast way to style and develop any app.\n        In the case of ReScript, it's extremely convenient because we can define an array of\n        strings in ReScript and easily combine them as ", React.createElement("em", undefined, "classNames "), "in any component."), React.createElement(ChapterParagraph.make, {
                  children: "In addition, this book is less about learning conventions of design and more focused\n        on using ReScript React to built cool state-based front end features users will love.\n        To continue with the book, it's highly recommended that you install Tailwind as described\n        below so you don't have to translate all the styling into traditional CSS / SCSS.\n        Later, when you're building you're own app, you can decide which styling strategy\n        is best."
                }), React.createElement(ChapterBox.make, {
                  number: "3.1",
                  title: "ReScript Flavored Stylesheets: Goals",
                  children: null
                }, React.createElement("p", undefined, "In this chapter we will accomplish the following:"), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "Install Tailwind CSS for managing our styles."), React.createElement("li", undefined, "Install rescript-classnames to concatenate classes."), React.createElement("li", undefined, "Configure a Tailwind theme."), React.createElement("li", undefined, "Build a basic home page."))));
}

var Section;

var Title;

var P;

var Link;

var Box;

var make = SectionTailwindOverview;

export {
  Section ,
  Title ,
  P ,
  Link ,
  Box ,
  make ,
}
/* react Not a pure module */
