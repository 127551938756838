// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var h2 = Cx.cx([
      "text-3xl",
      "font-medium"
    ]);

var paletteContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "pb-8",
      "border-b",
      "border-blue-gray-200"
    ]);

var palette = Cx.cx([
      "grid",
      "grid-cols-4",
      "gap-8"
    ]);

var colorContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "p-2",
      "rounded-md",
      "border-solid",
      "border",
      "border-blue-gray-100"
    ]);

var color = Cx.cx([
      "h-20",
      "rounded-md"
    ]);

export {
  h2 ,
  paletteContainer ,
  palette ,
  colorContainer ,
  color ,
}
/* h2 Not a pure module */
