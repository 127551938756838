// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "p-8",
      "flex",
      "flex-col",
      "gap-8"
    ]);

export {
  container ,
}
/* container Not a pure module */
