// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactScroll from "react-scroll";

function scrollTop(param) {
  return window.pageYOffset;
}

function scrollTo($$float) {
  ReactScroll.animateScroll.scrollTo($$float | 0);
}

var AnimateScroll = {
  scrollTo: scrollTo
};

var Link = {};

var $$Element = {};

export {
  scrollTop ,
  AnimateScroll ,
  Link ,
  $$Element ,
}
/* react-scroll Not a pure module */
