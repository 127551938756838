// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterParagraph(props) {
  return React.createElement("p", {
              className: ChapterStyles.Page.paragraph
            }, props.children);
}

var Css;

var make = ChapterParagraph;

export {
  Css ,
  make ,
}
/* react Not a pure module */
