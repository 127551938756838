// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DesignsHeader from "../styles/DesignsHeader.res.js";
import * as DesignsLayout from "../layout/DesignsLayout.res.js";
import * as DesignsContent from "../layout/components/DesignsContent.res.js";
import * as DesignPaletteColors from "./components/DesignPaletteColors.res.js";

function DesignsPalette$default(props) {
  return React.createElement(DesignsLayout.make, {
              currentLink: "Palette",
              children: Caml_option.some(React.createElement(DesignsContent.make, {
                        children: null
                      }, React.createElement("h1", {
                            className: DesignsHeader.h1
                          }, "Design Palette"), React.createElement(DesignPaletteColors.make, {
                            title: "Primary (Purple)",
                            colors: [
                              "bg-purple-900",
                              "bg-purple-800",
                              "bg-purple-700",
                              "bg-purple-600",
                              "bg-purple-500",
                              "bg-purple-400",
                              "bg-purple-300",
                              "bg-purple-200",
                              "bg-purple-100",
                              "bg-purple-50"
                            ]
                          }), React.createElement(DesignPaletteColors.make, {
                            title: "Secondary (Red Vivid)",
                            colors: [
                              "bg-red-vivid-900",
                              "bg-red-vivid-800",
                              "bg-red-vivid-700",
                              "bg-red-vivid-600",
                              "bg-red-vivid-500",
                              "bg-red-vivid-400",
                              "bg-red-vivid-300",
                              "bg-red-vivid-200",
                              "bg-red-vivid-100",
                              "bg-red-vivid-50"
                            ]
                          }), React.createElement(DesignPaletteColors.make, {
                            title: "Neutrals (Blue Gray)",
                            colors: [
                              "bg-blue-gray-900",
                              "bg-blue-gray-800",
                              "bg-blue-gray-700",
                              "bg-blue-gray-600",
                              "bg-blue-gray-500",
                              "bg-blue-gray-400",
                              "bg-blue-gray-300",
                              "bg-blue-gray-200",
                              "bg-blue-gray-100",
                              "bg-blue-gray-50"
                            ]
                          }), React.createElement(DesignPaletteColors.make, {
                            title: "Supporting (Yellow Vivid)",
                            colors: [
                              "bg-yellow-vivid-900",
                              "bg-yellow-vivid-800",
                              "bg-yellow-vivid-700",
                              "bg-yellow-vivid-600",
                              "bg-yellow-vivid-500",
                              "bg-yellow-vivid-400",
                              "bg-yellow-vivid-300",
                              "bg-yellow-vivid-200",
                              "bg-yellow-vivid-100",
                              "bg-yellow-vivid-50"
                            ]
                          }), React.createElement(DesignPaletteColors.make, {
                            title: "Supporting (Cyan)",
                            colors: [
                              "bg-cyan-900",
                              "bg-cyan-800",
                              "bg-cyan-700",
                              "bg-cyan-600",
                              "bg-cyan-500",
                              "bg-cyan-400",
                              "bg-cyan-300",
                              "bg-cyan-200",
                              "bg-cyan-100",
                              "bg-cyan-50"
                            ]
                          })))
            });
}

var Css;

var $$default = DesignsPalette$default;

export {
  Css ,
  $$default as default,
}
/* react Not a pure module */
