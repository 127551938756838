// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import TypeitReact from "typeit-react";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterTypeIt(props) {
  return React.createElement("div", {
              className: ChapterStyles.typeIt
            }, React.createElement(TypeitReact, {
                  options: {
                    strings: props.messages
                  }
                }), React.createElement("div", {
                  className: ChapterStyles.author
                }, "By Michael Price"));
}

var Css;

var make = ChapterTypeIt;

export {
  Css ,
  make ,
}
/* react Not a pure module */
