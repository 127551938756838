// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as DesignsTypesStyles from "../DesignsTypesStyles.res.js";

function DesignTypesExample(props) {
  return React.createElement("div", {
              className: Cx.cx([
                    DesignsTypesStyles.example,
                    props.style
                  ])
            }, React.createElement("div", {
                  className: DesignsTypesStyles.exampleTitle
                }, props.title), React.createElement("div", {
                  className: DesignsTypesStyles.exampleText
                }, props.text));
}

var Css;

var make = DesignTypesExample;

export {
  Css ,
  make ,
}
/* react Not a pure module */
