// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as PulseCss from "./Pulse.css";

var css = PulseCss;

function sizeToClassName(size) {
  switch (size) {
    case "Small" :
        return "h-5 w-5";
    case "Medium" :
        return "h-10 w-10";
    case "Large" :
        return "h-20 w-20";
    
  }
}

function className(size) {
  return Cx.cx([
              "blob",
              sizeToClassName(size),
              "bg-red-vivid-400",
              "shadow",
              "shadow-red-vivid-100"
            ]);
}

function Pulse(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "Small";
  return React.createElement("div", {
              className: className(size)
            });
}

var make = Pulse;

export {
  css ,
  sizeToClassName ,
  className ,
  make ,
}
/* css Not a pure module */
