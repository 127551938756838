// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var header = Cx.cx([
      "absolute",
      "top-0",
      "left-0",
      "right-0",
      "bg-transparent",
      "py-6",
      "px-10",
      "z-10"
    ]);

var navigation = Cx.cx([
      "flex",
      "justify-between",
      "items-center"
    ]);

var logoContainer = Cx.cx([
      "flex",
      "items-center",
      "gap-2",
      "font-bold"
    ]);

var logo = Cx.cx(["h-8"]);

var login = Cx.cx([
      "flex",
      "items-center",
      "gap-2",
      "font-bold",
      "relative"
    ]);

var icon = Cx.cx([
      "w-6",
      "h-6",
      "hover:text-red-500"
    ]);

var navigationLink = Cx.cx([]);

var bookMenu = Cx.cx([
      "absolute",
      "right-0",
      "top-6",
      "z-10",
      "mt-2",
      "w-60",
      "origin-top-right",
      "rounded-md",
      "bg-white",
      "py-1",
      "shadow-lg",
      "ring-1",
      "ring-black",
      "\n  ring-opacity-5",
      "focus:outline-none"
    ]);

var bookMenuItem = Cx.cx([
      "block",
      "px-4",
      "py-2",
      "text-sm",
      "text-gray-700",
      "font-normal",
      "hover:font-bold",
      "hover:text-purple-500",
      "hover:bg-gray-100"
    ]);

export {
  header ,
  navigation ,
  logoContainer ,
  logo ,
  login ,
  icon ,
  navigationLink ,
  bookMenu ,
  bookMenuItem ,
}
/* header Not a pure module */
