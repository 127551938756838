// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import HighlightJs from "highlight.js";

let hljs = require('highlight.js/lib/core');
  let rescript = require('./rescript-highlightjs');
  hljs.registerLanguage('rescript', rescript);
;

function highlight(element, language) {
  return HighlightJs.highlight(element, language).value;
}

function highlightAuto(element) {
  return HighlightJs.highlightAuto(element).value;
}

function toString(langauge) {
  switch (langauge) {
    case "Bash" :
        return "bash";
    case "JavaScript" :
        return "javascript";
    case "ERB" :
        return "erb";
    case "Ruby" :
        return "ruby";
    case "Console" :
    case "Shell" :
        return "shell";
    case "Markdown" :
        return "markdown";
    case "YAML" :
        return "yaml";
    case "Dockerfile" :
        return "dockerfile";
    case "JSON" :
        return "json";
    case "TypeScript" :
        return "typescript";
    case "ReasonML" :
        return "reasonml";
    case "CSS" :
        return "css";
    case "ReScript" :
        return "rescript";
    case "Docker" :
        return "docker";
    
  }
}

function toClassName(langauge) {
  return "language-" + toString(langauge);
}

var Language = {
  toString: toString,
  toClassName: toClassName
};

function HighlightJs$1(props) {
  var className = props.className;
  var language = props.language;
  var html = highlight(props.element, {
        language: toString(language)
      });
  return React.createElement("pre", undefined, React.createElement("code", {
                  className: Cx.cx([
                        className !== undefined ? className : "",
                        toClassName(language),
                        "hljs"
                      ]),
                  dangerouslySetInnerHTML: {
                    __html: html
                  }
                }));
}

var make = HighlightJs$1;

export {
  highlight ,
  highlightAuto ,
  Language ,
  make ,
}
/*  Not a pure module */
