// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "block",
      "m-2",
      "h-screen",
      "bg-white",
      "font-['Open_Sans']",
      "text-base"
    ]);

var h3 = Cx.cx([
      "block",
      "text-[1.17em]",
      "font-bold",
      "mbs-[1em]",
      "mbe-[1em]",
      "mis-0",
      "mie-0"
    ]);

var hr = Cx.cx([
      "block",
      "mbs-[0.5em]",
      "mbe-[0.5em]",
      "mis-auto",
      "mie-auto",
      "overflow-hidden",
      "border-t",
      "border-black",
      "border-inset"
    ]);

var input = Cx.cx([
      "border-inset",
      "inline-block",
      "border-1",
      "py-px",
      "px-0.5",
      "text-sm",
      "font-sans"
    ]);

export {
  container ,
  h3 ,
  hr ,
  input ,
}
/* container Not a pure module */
