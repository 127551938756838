// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as TypeIt from "../../../../../bindings/TypeIt.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "justify-center",
      "gap-10",
      "px-10",
      "md:px-20",
      "py-10",
      "md:py-32",
      "relative",
      "overflow-hidden",
      "h-screen"
    ]);

var pulse = Cx.cx(["mb-10"]);

var typeIt = Cx.cx([
      TypeIt.Style.text,
      "text-center",
      "text-4xl",
      "md:text-5xl"
    ]);

var subTitle = Cx.cx([
      "text-center",
      "text-2xl",
      "text-gray-500"
    ]);

var glow = Cx.cx([
      "absolute",
      "rounded-full",
      "-z-10",
      "opacity-20",
      "mix-blend-multiply",
      "blur-3xl"
    ]);

var glowLeft = Cx.cx([
      glow,
      "left-0",
      "-top-16",
      "w-160",
      "h-160",
      "bg-yellow-vivid-500"
    ]);

var glowMiddle = Cx.cx([
      glow,
      "-bottom-16",
      "w-112",
      "h-112",
      "bg-teal-vivid-500"
    ]);

var glowRight = Cx.cx([
      glow,
      "-right-16",
      "-bottom-40",
      "w-144",
      "h-144",
      "bg-purple-500"
    ]);

export {
  container ,
  pulse ,
  typeIt ,
  subTitle ,
  glow ,
  glowLeft ,
  glowMiddle ,
  glowRight ,
}
/* container Not a pure module */
