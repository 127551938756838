// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterPage from "../../../chapters/components/ChapterPage.res.js";
import * as ChapterTypeIt from "../../../chapters/components/ChapterTypeIt.res.js";
import * as FramerMotion from "framer-motion";
import * as SectionForward from "./sections/SectionForward.res.js";
import * as ChapterContainer from "../../../chapters/components/ChapterContainer.res.js";
import * as ReactA11yFootnotes from "react-a11y-footnotes";

function Forward$default(props) {
  return React.createElement(FramerMotion.AnimatePresence, {
              children: React.createElement(ChapterContainer.make, {
                    previous: undefined,
                    next: props.chapter,
                    children: React.createElement(ReactA11yFootnotes.FootnotesProvider, {
                          children: null
                        }, React.createElement(ChapterTypeIt.make, {
                              messages: [
                                "Forward",
                                ""
                              ]
                            }), React.createElement(ChapterPage.make, {
                              children: React.createElement(SectionForward.make, {})
                            }))
                  })
            });
}

var Container;

var Page;

var $$default = Forward$default;

export {
  Container ,
  Page ,
  $$default as default,
}
/* react Not a pure module */
