// Generated by ReScript, PLEASE EDIT WITH CARE


var badInt = {
  number: "2.1",
  text: "Type is Integer, but expecting a String"
};

var goodInt = {
  number: "2.2",
  text: "Type is Integer, and is converted to a String"
};

var railsIntToString = {
  number: "2.3",
  text: "Rails string work directly with integers. No conversion needed"
};

var bsConfig = {
  number: "2.3",
  text: "The rescript.json file in the root folder"
};

var helloWorldRes = {
  number: "2.4",
  text: "The new HelloWorld.res file"
};

var applicationJs = {
  number: "2.5",
  text: "Update the application and server js files with new HelloWorld.bs.js"
};

var gitignore = {
  number: "2.6",
  text: "Update the .gitigonore file"
};

var procfileDev = {
  number: "2.7",
  text: "Update the Profile.dev file"
};

var shakapackerYml = {
  number: "2.8",
  text: "Update the shakapacker.yml file"
};

var brightStyle = {
  number: "2.8",
  text: "Update application.css with bright styling"
};

export {
  badInt ,
  goodInt ,
  railsIntToString ,
  bsConfig ,
  helloWorldRes ,
  applicationJs ,
  gitignore ,
  procfileDev ,
  shakapackerYml ,
  brightStyle ,
}
/* No side effect */
