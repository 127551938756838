// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Create_cpln_orgPng from "../images/create_cpln_org.png";
import Select_cpln_accountPng from "../images/select_cpln_account.png";
import Control_plane_sign_upPng from "../images/control_plane_sign_up.png";
import Create_cpln_org_detailsPng from "../images/create_cpln_org_details.png";

var controlPlaneSignUp = Control_plane_sign_upPng;

var createCplnOrg = Create_cpln_orgPng;

var selectCplnAccount = Select_cpln_accountPng;

var createCplnOrgDetails = Create_cpln_org_detailsPng;

function SectionCplnSignUp(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Control Plane Setup"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Next, you need to sign up for an account with Control Plane and create an organization."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Sign Up",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Navigate to Control Plane's sign up page here: ", React.createElement(ChapterLink.make, {
                      href: "https://console.cpln.io/signup",
                      text: "https://console.cpln.io/signup"
                    }), ". ", "Pick the sign up option that works best for you, and follow the prompts to create an account. "), React.createElement(ChapterParagraph.make, {
                  children: React.createElement(ChapterFile.make, {
                        text: "NOTE: Once you sign up, you may need to wait for approval from Control Plane before you can proceed."
                      })
                }), React.createElement(ChapterImage.make, {
                  id: "controlPlaneSignUp",
                  caption: "Figure 4.2: Control Plane Sign Up",
                  imageType: "Static",
                  image: controlPlaneSignUp
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Create an Organization",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "As discussed earlier, an Organization (\"Org\") is at the top of the heirarchy of the\n        Control Plane concepts. Control Plane may automatically start by having you\n        create your first organization. If not, click on \"Create\" in the top right corner\n        of the dashboard navigation and click \"Org\"."
                }), React.createElement(ChapterImage.make, {
                  id: "createCplnOrg",
                  caption: "Figure 4.3: Click \"Org\" to create a Control Plane Organization",
                  className: ChapterStyles.$$Image.imageHalf,
                  imageType: "Static",
                  image: createCplnOrg
                }), React.createElement(ChapterParagraph.make, {
                  children: "If prompted, select the Control Plane account you want to use for your organization\n        and click \"Continue\". You can create multiple organizations under a single account.\n        You likely only have one account at this time."
                }), React.createElement(ChapterImage.make, {
                  id: "selectCplnAccount",
                  caption: "Figure 4.4: Select the Control Plane account for your Organization",
                  imageType: "Static",
                  image: selectCplnAccount
                }), "Now, fill out the details of your organization. Add any other admin emails you want to\n      have access to the organization. Click \"Create\" when you're done.", React.createElement(ChapterImage.make, {
                  id: "createCplnOrgDetails",
                  caption: "Figure 4.5: Fill out the details of your Control Plane Organization",
                  imageType: "Static",
                  image: createCplnOrgDetails
                }), React.createElement(ChapterParagraph.make, {
                  children: "With the basic Control Plane setup complete, we can now get connected on our\n        system using the Control Plane CLI."
                }));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var $$Image;

var Css;

var make = SectionCplnSignUp;

export {
  controlPlaneSignUp ,
  createCplnOrg ,
  selectCplnAccount ,
  createCplnOrgDetails ,
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  $$Image ,
  Css ,
  make ,
}
/* controlPlaneSignUp Not a pure module */
