// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as PreviewBrowserStyles from "./PreviewBrowserStyles.res.js";

function PreviewBrowser(props) {
  var onClick = props.onClick;
  var __url = props.url;
  var url = __url !== undefined ? __url : "https://localhost:3000";
  return React.createElement("div", {
              className: PreviewBrowserStyles.container
            }, React.createElement("div", {
                  className: PreviewBrowserStyles.fakeBrowser
                }, React.createElement("header", {
                      className: PreviewBrowserStyles.fakeBrowserHeader,
                      onClick: onClick
                    }, React.createElement("div", {
                          className: PreviewBrowserStyles.actionButtons
                        }, React.createElement("span", {
                              className: PreviewBrowserStyles.actionButtonRed
                            }), React.createElement("span", {
                              className: PreviewBrowserStyles.actionButtonOrange
                            }), React.createElement("span", {
                              className: PreviewBrowserStyles.actionButtonGreen
                            })), React.createElement("div", {
                          className: PreviewBrowserStyles.addressBar
                        }, React.createElement("input", {
                              className: PreviewBrowserStyles.input,
                              type: "text",
                              value: url,
                              onChange: (function (param) {
                                  
                                })
                            })), React.createElement("div", {
                          className: PreviewBrowserStyles.settingMore,
                          onClick: onClick
                        }, React.createElement("span", {
                              className: PreviewBrowserStyles.moreButton
                            }))), React.createElement("section", {
                      className: PreviewBrowserStyles.fakeWindowBody
                    }, props.children)));
}

var Css;

var make = PreviewBrowser;

export {
  Css ,
  make ,
}
/* react Not a pure module */
