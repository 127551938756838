// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DesignsHeader from "../../styles/DesignsHeader.res.js";
import * as DesignsPaletteStyles from "../DesignsPaletteStyles.res.js";

function DesignPaletteColors(props) {
  return React.createElement("div", {
              className: DesignsPaletteStyles.paletteContainer
            }, React.createElement("h2", {
                  className: DesignsHeader.Section.h2
                }, props.title), React.createElement("div", {
                  className: DesignsPaletteStyles.palette
                }, Belt_Array.mapWithIndex(props.colors, (function (index, color) {
                        return React.createElement("div", {
                                    className: DesignsPaletteStyles.colorContainer
                                  }, React.createElement("div", {
                                        key: index.toString() + "-" + color,
                                        className: Cx.cx([
                                              DesignsPaletteStyles.color,
                                              color
                                            ])
                                      }), React.createElement("div", undefined, color));
                      }))));
}

var Css;

var make = DesignPaletteColors;

export {
  Css ,
  make ,
}
/* react Not a pure module */
