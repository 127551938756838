// Generated by ReScript, PLEASE EDIT WITH CARE


function toTitle(t) {
  return "Listing " + t.number + ": " + t.text + ".";
}

export {
  toTitle ,
}
/* No side effect */
