// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SectionBasicHomePage from "./sections/SectionBasicHomePage.res.js";
import * as SectionTailwindOverview from "./sections/SectionTailwindOverview.res.js";
import * as SectionTailwindInstallation from "./sections/SectionTailwindInstallation.res.js";

function Chapter03(props) {
  var setScroll = props.setScroll;
  return React.createElement(React.Fragment, {}, React.createElement(SectionTailwindOverview.make, {}), React.createElement(SectionTailwindInstallation.make, {
                  setScroll: setScroll
                }), React.createElement(SectionBasicHomePage.make, {
                  setScroll: setScroll
                }));
}

var make = Chapter03;

export {
  make ,
}
/* react Not a pure module */
