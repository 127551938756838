// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pulse from "../../../styleguide/pulse/Pulse.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/buttons/Button.res.js";
import TypeitReact from "typeit-react";
import * as HomeHeroStyles from "./styles/HomeHeroStyles.res.js";

function HomeHero(props) {
  var __subTitle = props.subTitle;
  var __headline = props.headline;
  var headline = __headline !== undefined ? __headline : "Welcome to ReScript React on Rails";
  var subTitle = __subTitle !== undefined ? __subTitle : "A detailed guide to advanced programming in Rails with ReScript";
  return React.createElement("div", {
              className: HomeHeroStyles.container
            }, React.createElement("div", {
                  className: HomeHeroStyles.glowLeft
                }), React.createElement("div", {
                  className: HomeHeroStyles.glowMiddle
                }), React.createElement("div", {
                  className: HomeHeroStyles.glowRight
                }), React.createElement("div", {
                  className: HomeHeroStyles.pulse
                }, React.createElement(Pulse.make, {
                      size: "Large"
                    })), React.createElement("div", {
                  className: HomeHeroStyles.typeIt
                }, React.createElement(TypeitReact, {
                      options: {
                        strings: [headline],
                        speed: 50,
                        element: "div"
                      }
                    })), React.createElement("div", {
                  className: HomeHeroStyles.subTitle
                }, subTitle), React.createElement(Button.make, {
                  text: "Get Started",
                  href: "/chapters/forward",
                  color: "Red"
                }));
}

var Css;

var make = HomeHero;

export {
  Css ,
  make ,
}
/* Pulse Not a pure module */
