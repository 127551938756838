// Generated by ReScript, PLEASE EDIT WITH CARE


var xs = "text-xs";

var sm = "text-sm";

var base = "text-base";

var lg = "text-lg";

var xl = "text-xl";

var xl2 = "text-2xl";

var xl3 = "text-3xl";

var xl4 = "text-4xl";

var xl5 = "text-5xl";

export {
  xs ,
  sm ,
  base ,
  lg ,
  xl ,
  xl2 ,
  xl3 ,
  xl4 ,
  xl5 ,
}
/* No side effect */
