// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterImage(props) {
  var __imageType = props.imageType;
  var __className = props.className;
  var caption = props.caption;
  var className = __className !== undefined ? __className : ChapterStyles.$$Image.image;
  var imageType = __imageType !== undefined ? __imageType : "Static";
  var tmp;
  tmp = imageType === "Static" ? null : React.createElement("div", {
          className: ChapterStyles.$$Image.overlayText
        }, "Click to Preview");
  return React.createElement(React.Fragment, {}, React.createElement("div", {
                  className: ChapterStyles.$$Image.container,
                  id: props.id,
                  onClick: props.onClick
                }, React.createElement("img", {
                      className: className,
                      src: props.image
                    }), tmp), caption !== undefined ? React.createElement("div", {
                    className: ChapterStyles.$$Image.caption
                  }, Js_option.getExn(Caml_option.valFromOption(caption))) : null);
}

var Css;

var make = ChapterImage;

export {
  Css ,
  make ,
}
/* react Not a pure module */
