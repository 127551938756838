// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Info(props) {
  return React.createElement("svg", {
              className: "w-6 h-6",
              fill: "none",
              stroke: "currentColor",
              strokeWidth: "1.5",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z",
                  strokeLinecap: "round",
                  strokeLinejoin: "round"
                }));
}

var make = Info;

export {
  make ,
}
/* react Not a pure module */
