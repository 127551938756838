// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter03Code from "../components/Chapter03Code.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter03Listings from "../components/Chapter03Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Rescript_new_homePng from "../images/rescript_new_home.png";
import Rescript_new_home_initialPng from "../images/rescript_new_home_initial.png";

var rescriptNewHomeInitial = Rescript_new_home_initialPng;

var rescriptNewHome = Rescript_new_homePng;

function SectionBasicHomePage(props) {
  var setScroll = props.setScroll;
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Home Page"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Together, we're going to build a website that you can use as a reference\n        guide for developing in ReScript and Rails. Something like a cookbook\n        to rapidly prototype and ship an application. We'll start with a home\n        page then then add to it as we go."
                }), React.createElement(ChapterParagraph.make, {
                  children: "Website design isn't my strong suite, so I apologize up front if this\n        isn't to your taste."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Tailwind Theme",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Having a clear theme to your website can be extremely helpful and\n        simplify your development. Tailwind makes this simple by allowing us\n        to declare theme colors at a global level in the ", React.createElement(ChapterFile.make, {
                      text: "tailwind.config.js"
                    }), " file."), React.createElement(ChapterParagraph.make, {
                  children: "For the sake of convenience, and partly because I'm lazy, we'll\n        use the same theme I created for this tutorial."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.tailwindThemeConfig)),
                  language: "JavaScript",
                  children: Chapter03Code.tailwindThemeConfig
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.finishTailwind
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Home Page Backend",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "When working in this stack, I like to follow a basic pattern when\n        adding new pages. First, I add all the backend components necessary\n        to get the page to render without ReScript."
                }), React.createElement(ChapterBox.make, {
                  number: "3.3",
                  title: "Basic pattern for adding new pages with Rails & ReScript",
                  children: null
                }, React.createElement("strong", {
                      className: "font-bold"
                    }, "Backend Steps:"), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "Add action to the controller."), React.createElement("li", undefined, "Add route to the routes file."), React.createElement("li", undefined, "Add view to the views folder.")), React.createElement("strong", {
                      className: "font-bold"
                    }, "Frontend Steps:"), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "Create a new ReScript file with a basic component."), React.createElement("li", undefined, "Declare the file in application.js and application-server.js."), React.createElement("li", undefined, "Add code to render ReScript component in the view."))), React.createElement(ChapterSectionTitle.make, {
                  text: "Backend Steps",
                  titleType: "Mini"
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.homepageController)),
                  language: "Ruby",
                  children: Chapter03Code.homepageController
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.homepageRoutes)),
                  language: "Ruby",
                  children: Chapter03Code.homepageRoutes
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.homepageHtml)),
                  language: "Console",
                  children: Chapter03Code.homepageView
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "When you spin up the server, you'll see a blank page and visit ", React.createElement(ChapterFile.make, {
                      text: "/hello_world"
                    }), " ", "you'll see the original ", React.createElement(ChapterFile.make, {
                      text: "HelloWorld"
                    }), " component."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.commitHomepage
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Frontend Steps",
                  titleType: "Mini"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "When organizing ReScript files in a Rails project, I like to follow a similar\n        structure to the Rails files and folders. This makes it easier to find the files I need\n        without having to trace them. With the current example, the home page gets added to the ", React.createElement(ChapterFile.make, {
                      text: "app/javascript/bundles/views/pages/home"
                    }), " directory."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "The file is also named with the ControllerView.res naming pattern. In this case,\n        it will be named ", React.createElement(ChapterFile.make, {
                      text: "PagesHome.res"
                    }), ".", "You could go further and add \"Views\" to the name, but names can get long\n        if you're not careful."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "The ReScript home directory will hold styles, components, and other files.\n        Later when we work with models, we'll have a models folder with ModelName.res\n        files that parallel the Rails ", React.createElement(ChapterFile.make, {
                      text: "app/models"
                    }), " files."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.addHomepageRes)),
                  language: "Console",
                  children: Chapter03Code.addHomepageRes
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.homepageRes)),
                  language: "ReScript",
                  children: Chapter03Code.homepageRes
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.declareHomepage)),
                  language: "JavaScript",
                  children: Chapter03Code.declareHomepage
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.updateHomepageView)),
                  language: "ERB",
                  children: Chapter03Code.updateHomepageView
                }), React.createElement(ChapterParagraph.make, {
                  children: "Go ahead and fire up the server to make sure everything is working as expected.\n        You should see something like the following:"
                }), React.createElement(ChapterImage.make, {
                  id: "rescriptNewHomeInitial",
                  caption: "Figure 3.3: Basic New Home Page",
                  onClick: (function ($$event) {
                      setScroll($$event, "RescriptNewHomeInitial");
                    }),
                  imageType: "Preview",
                  image: rescriptNewHomeInitial
                }), React.createElement(ChapterParagraph.make, {
                  children: "If your page is working, commit what we have so far."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.commitInitialNewHome
                }), React.createElement(ChapterParagraph.make, {
                  children: "To finish this chapter up and give the home page some flourish, let's add\n        some color."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.addHomepageStyles
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.homepageStyles)),
                  language: "ReScript",
                  children: Chapter03Code.homepageStyles
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.homepageResColor)),
                  language: "ReScript",
                  children: Chapter03Code.homepageResColor
                }), React.createElement(ChapterParagraph.make, {
                  children: "Spin up the server and check out your hard work!"
                }), React.createElement(ChapterImage.make, {
                  id: "rescriptNewHomeStyles",
                  caption: "Figure 3.4: New Home Page with Style",
                  onClick: (function ($$event) {
                      setScroll($$event, "RescriptNewHomeColor");
                    }),
                  imageType: "Preview",
                  image: rescriptNewHome
                }), React.createElement(ChapterParagraph.make, {
                  children: "Now that you're officially a ReScript on Rails developer, we're ready\n        to do some really cool stuff together. On to deployment!"
                }));
}

var Section;

var Title;

var P;

var $$File;

var Code;

var Listings;

var Box;

var make = SectionBasicHomePage;

export {
  rescriptNewHomeInitial ,
  rescriptNewHome ,
  Section ,
  Title ,
  P ,
  $$File ,
  Code ,
  Listings ,
  Box ,
  make ,
}
/* rescriptNewHomeInitial Not a pure module */
