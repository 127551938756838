// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter03Code from "../components/Chapter03Code.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter03Listings from "../components/Chapter03Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Hello_world_react_typingPng from "../images/hello_world_react_typing.png";
import Rescript_hello_world_tailwindPng from "../images/rescript_hello_world_tailwind.png";

var rescriptTailwind = Rescript_hello_world_tailwindPng;

var rescriptHelloWorld = Hello_world_react_typingPng;

function SectionTailwindInstallation(props) {
  var setScroll = props.setScroll;
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Installation"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "We are going use the ", React.createElement(ChapterLink.make, {
                      href: "https://tailwindcss.com/docs/guides/ruby-on-rails",
                      text: "tailwindcss-rails gem "
                    }), "to install Tailwind, which will make it available to both ReScript and the Rails\n        asset pipeline."), React.createElement(ChapterSectionTitle.make, {
                  text: "Add Tailwind CSS",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Update your ", React.createElement(ChapterFile.make, {
                      text: "Gemfile"
                    }), " ", "with the following code."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.tailwindGemfile)),
                  language: "Ruby",
                  children: Chapter03Code.gemfile
                }), React.createElement(ChapterParagraph.make, {
                  children: "Then run the commands to install Tailwind."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.installTailwind
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "The installation command will insert several lines of code throughout the application.\n        During the installation it will ask if you want to overwrite the ", React.createElement(ChapterFile.make, {
                      text: "bin/dev"
                    }), " file.", "Be sure to type 'n' for \"No\" and hit enter."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.generateTailwind
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "When you run ", React.createElement(ChapterFile.make, {
                      text: "$ foreman start -f Procfile.dev"
                    }), " ", "to restart the server, you'll notice that the styles of the current home\n        page have changed."), React.createElement(ChapterImage.make, {
                  id: "rescriptTailwind",
                  caption: "Figure 3.1: ReScript Tailwind Hello World",
                  onClick: (function ($$event) {
                      setScroll($$event, "RescriptHelloWorldTailwind");
                    }),
                  imageType: "Preview",
                  image: rescriptTailwind
                }), React.createElement(ChapterParagraph.make, {
                  children: "Go ahead and shut down the server. Next, we'll add a couple more packages that\n        make development life easier."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "ReScript Classnames",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "We can get the styling back close the original. But, first we'll install ", React.createElement(ChapterLink.make, {
                      href: "https://github.com/shakacode/rescript-classnames",
                      text: "rescript-classnames "
                    }), "to help with classname declarations. Add these packages, then update ", React.createElement(ChapterFile.make, {
                      text: "rescript.json"
                    }), "."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.rescriptClassnames
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.bsConfigClassnames)),
                  language: "JSON",
                  children: Chapter03Code.bsConfigClassnames
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "The ", React.createElement(ChapterFile.make, {
                      text: "bsc-flags"
                    }), " ", "configuration option tells ReScript to automatically open Cx, which makes the ", React.createElement(ChapterFile.make, {
                      text: "cx([...])"
                    }), " ", "function immediately available to any ReScript module without having to declare it in\n        every file."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "We also added ", React.createElement(ChapterLink.make, {
                      href: "https://github.com/stevecochrane/tailwindcss-logical",
                      text: "tailwindcss-logical "
                    }), "to handle more subtle CSS declarations from time-to-time. Update\n        the Tailwind config file with the following code."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.tailwindConfig)),
                  language: "JavaScript",
                  children: Chapter03Code.tailwindConfig
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "We're now ready to style with ReScript. Let's get the ", React.createElement(ChapterFile.make, {
                      text: "HelloWorld.res"
                    }), " ", "component looking like it did before using Tailwind and rescript-classnames."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.createHelloWorldStyles
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "As a convention, I name style files using the related component's name like ", React.createElement(ChapterFile.make, {
                      text: "ComponentNameStyles.res"
                    }), " ", "and place the file in the same folder as the component. For me style files are easy to find\n        when organized this way."), React.createElement(ChapterParagraph.make, {
                  children: "Add this code to the new file leaving out the commments unless you want them for\n        reference later."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.helloWorldStyles)),
                  language: "ReScript",
                  children: Chapter03Code.helloWorldStyles
                }), React.createElement(ChapterParagraph.make, {
                  children: "Then add the new styles to the component:"
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.helloWorldComponentStyles)),
                  language: "ReScript",
                  children: Chapter03Code.updatedHelloWorld
                }), React.createElement(ChapterParagraph.make, {
                  children: "ReScript (.res) file names must be unique. At times this can result in names that are quite long.\n        By declaring a module called Css at the top of the file, we can make the rest of the code\n        more readable. It also makes clear the purpose of the module. Note, this is a personal\n        preference and completely optional."
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "One last step is to remove the default classes given by the Tailwind generator to ", React.createElement(ChapterFile.make, {
                      text: "<main />"
                    }), " tag in ", React.createElement(ChapterFile.make, {
                      text: "application.html.erb"
                    }), "."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter03Listings.updatedApplicationHtml)),
                  language: "ERB",
                  children: Chapter03Code.updatedApplicationHtml
                }), React.createElement(ChapterParagraph.make, {
                  children: "Spin up the Rails server and check out what you've accomplished."
                }), React.createElement(ChapterImage.make, {
                  id: "helloWorldColor",
                  caption: "Figure 3.2: ReScript Hello World with ReScript Tailwind styles!",
                  onClick: (function ($$event) {
                      setScroll($$event, "RescriptHelloWorldColor");
                    }),
                  imageType: "Preview",
                  image: rescriptHelloWorld
                }), React.createElement(ChapterBox.make, {
                  number: "3.2",
                  title: "Getting a compilation error?",
                  color: "Red",
                  children: null
                }, React.createElement("p", undefined, "IMPORTANT: If after installing rescript-classnames you are getting a compilation error from ReScript, you may need to explicitly\n          set the ReScript version number at 11.1.0 in package.json."), React.createElement("p", undefined, "There appears to be an issue or bug starting in rescript@11.1.2, which prevents\n          rescript-classnames from loading properly.")), React.createElement(ChapterParagraph.make, {
                  children: "Okay, we have our original component reimagined with ReScript and Tailwind.\n        Now we can create a new home page and bring everything together.\n        With these tools in hand, we can repidly prototype our application and\n        build reusable components and styles."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter03Code.commitTailwind
                }));
}

var Section;

var Title;

var P;

var Link;

var Code;

var Listings;

var $$File;

var Box;

var make = SectionTailwindInstallation;

export {
  rescriptTailwind ,
  rescriptHelloWorld ,
  Section ,
  Title ,
  P ,
  Link ,
  Code ,
  Listings ,
  $$File ,
  Box ,
  make ,
}
/* rescriptTailwind Not a pure module */
