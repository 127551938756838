// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterSection(props) {
  return React.createElement("div", {
              className: ChapterStyles.Page.section
            }, props.children);
}

var Css;

var make = ChapterSection;

export {
  Css ,
  make ,
}
/* react Not a pure module */
