// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FramerMotion from "framer-motion";

var style = "relative";

function ChapterPreview(props) {
  return React.createElement(FramerMotion.motion.div, {
              className: style,
              initial: {
                opacity: 0.0,
                x: "200%"
              },
              animate: {
                opacity: 1.0,
                x: "0"
              },
              exit: {
                opacity: 0.0,
                x: "200%"
              },
              transition: {
                duration: 0.3
              },
              children: props.children
            });
}

var make = ChapterPreview;

export {
  style ,
  make ,
}
/* react Not a pure module */
