// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "sticky",
      "bottom-0",
      "bg-white",
      "w-full",
      "p-4",
      "shadow-[0px_0_40px_-15px_rgba(0,0,0,0.5)]",
      "shadow-blue-gray-600",
      "opacity-90",
      "z-50"
    ]);

var leftContainer = Cx.cx([
      container,
      "justify-start"
    ]);

var fullContainer = Cx.cx([
      container,
      "justify-between"
    ]);

var rightContainer = Cx.cx([
      container,
      "justify-end"
    ]);

var arrowContainer = Cx.cx([
      "flex",
      "flex-row",
      "gap-6",
      "items-center"
    ]);

var arrowLink = Cx.cx([
      "flex",
      "gap-2"
    ]);

var arrowLinkReverse = Cx.cx([
      arrowLink,
      "flex-row-reverse"
    ]);

export {
  container ,
  leftContainer ,
  fullContainer ,
  rightContainer ,
  arrowContainer ,
  arrowLink ,
  arrowLinkReverse ,
}
/* container Not a pure module */
