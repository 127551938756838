// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterPreview from "../../components/ChapterPreview.res.js";
import * as PreviewRescriptHelloWorldStyles from "./PreviewRescriptHelloWorldStyles.res.js";

function PreviewRescriptHelloWorld(props) {
  var match = React.useState(function () {
        return "Everyone";
      });
  var setName = match[1];
  var name = match[0];
  return React.createElement(ChapterPreview.make, {
              children: React.createElement("div", {
                    className: PreviewRescriptHelloWorldStyles.container
                  }, React.createElement("div", undefined, React.createElement("h3", {
                            className: PreviewRescriptHelloWorldStyles.h3
                          }, "Hello, " + name + "!"), React.createElement("hr", {
                            className: PreviewRescriptHelloWorldStyles.hr
                          }), React.createElement("form", undefined, React.createElement("label", {
                                className: props.color ? "bright" : "",
                                htmlFor: "name"
                              }, "Say hello to:"), React.createElement("input", {
                                className: PreviewRescriptHelloWorldStyles.input,
                                id: "name",
                                type: "text",
                                value: name,
                                onChange: (function ($$event) {
                                    $$event.preventDefault();
                                    var value = $$event.target.value;
                                    setName(function (_prev) {
                                          return value;
                                        });
                                  })
                              }))))
            });
}

var Css;

var make = PreviewRescriptHelloWorld;

export {
  Css ,
  make ,
}
/* react Not a pure module */
