// Generated by ReScript, PLEASE EDIT WITH CARE


var Box_whatWellLearn = {
  number: "4.1",
  text: "What We'll Learn"
};

var Box_controlPlaneBenefits = {
  number: "4.2",
  text: "Benefits of Control Plane"
};

var Box_controlPlaneSteps = {
  number: "4.3",
  text: "Steps to Deploy to Control Plane"
};

var Box_postgresTemplates = {
  number: "4.4",
  text: "PostgreSQL Templates"
};

var Box_autoscaling = {
  number: "4.5",
  text: "Types of Autoscaling"
};

var Box = {
  whatWellLearn: Box_whatWellLearn,
  controlPlaneBenefits: Box_controlPlaneBenefits,
  controlPlaneSteps: Box_controlPlaneSteps,
  postgresTemplates: Box_postgresTemplates,
  autoscaling: Box_autoscaling
};

var cplnNpm = {
  number: "3.1",
  text: "Install Control Plane CLI with npm"
};

var cplnHelp = {
  number: "3.2",
  text: "List of Control Plane CLI commands"
};

var cplnLogin = {
  number: "3.3",
  text: "Login with the Control Plane CLI"
};

var cplnCliSuccess = {
  number: "3.4",
  text: "Control Plane CLI login success response"
};

var cplnImageLogin = {
  number: "3.5",
  text: "Control Plane CLI docker image login"
};

var cpflowHelp = {
  number: "3.6",
  text: "Control Plane Flow CLI help"
};

var cpflowGemfile = {
  number: "3.7",
  text: "Add Control Plane Flow gem to Gemfile"
};

var cpflowDockerFile = {
  number: "3.8",
  text: "Add Control Plane Flow Dockerfile"
};

var cpflowGvc = {
  number: "3.9",
  text: "Add Control Plane Flow gvc.yml template"
};

var cpflowPostgres = {
  number: "3.10",
  text: "Add Control Plane Flow postgres.yml template"
};

var cpflowRails = {
  number: "3.11",
  text: "Add Control Plane Flow rails.yml template"
};

var cpflowRedis = {
  number: "3.12",
  text: "Add Control Plane Flow redis.yml template"
};

var cpflowCplnYml = {
  number: "3.13",
  text: "Add Control Plane Flow controlplane.yml file"
};

var cpflowReleaseScript = {
  number: "3.14",
  text: "Add Control Plane Flow release_script.sh"
};

var cpflowEntrypoint = {
  number: "3.15",
  text: "Add Control Plane Flow entrypoint.sh"
};

export {
  cplnNpm ,
  cplnHelp ,
  cplnLogin ,
  cplnCliSuccess ,
  cplnImageLogin ,
  cpflowHelp ,
  cpflowGemfile ,
  cpflowDockerFile ,
  cpflowGvc ,
  cpflowPostgres ,
  cpflowRails ,
  cpflowRedis ,
  cpflowCplnYml ,
  cpflowReleaseScript ,
  cpflowEntrypoint ,
  Box ,
}
/* No side effect */
