import ReactOnRails from 'react-on-rails';

import Home from '../bundles/home/Home.res.js';
import HelloWorld from '../bundles/HelloWorld.res.js';
import ChapterShow from '../bundles/chapters/show/ChapterShow.res.js';
import DesignsIndex from '../bundles/designs/index/DesignsIndex.res.js';
import DesignsPalette from '../bundles/designs/palette/DesignsPalette.res.js';
import DesignsTypes from '../bundles/designs/types/DesignsTypes.res.js';
import Forward from '../bundles/views/chapters/forward/Forward.res.js';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  Home,
  HelloWorld,
  ChapterShow,
  DesignsIndex,
  DesignsPalette,
  DesignsTypes,
  Forward,
});
