// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterFile(props) {
  return React.createElement(React.Fragment, {
              children: Caml_option.some(React.createElement("span", {
                        className: ChapterStyles.Page.fileName
                      }, props.text))
            });
}

var Css;

var make = ChapterFile;

export {
  Css ,
  make ,
}
/* react Not a pure module */
