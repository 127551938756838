// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Chapter04Code from "../components/Chapter04Code.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionCplnDeploy(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Deploy to Control Plane"
                }), React.createElement(ChapterParagraph.make, {
                  children: "With all the necessary templates and files in place, you're ready to deploy\n        your application."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "JSON",
                  children: Chapter04Code.packageScripts
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter04Code.cpflowDeploy
                }));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var $$Image;

var Code;

var Listings;

var Css;

var make = SectionCplnDeploy;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  $$Image ,
  Code ,
  Listings ,
  Css ,
  make ,
}
/* react Not a pure module */
