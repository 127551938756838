// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "block",
      "m-12",
      "h-screen",
      "bg-white"
    ]);

export {
  container ,
}
/* container Not a pure module */
