// Generated by ReScript, PLEASE EDIT WITH CARE


function $$class(size) {
  switch (size) {
    case "XS" :
        return "w-6 h-6";
    case "SM" :
        return "w-10 h-10";
    case "MD" :
        return "w-14 h-14";
    case "LG" :
        return "w-20 h-20";
    case "XL" :
        return "w-28 h-28";
    
  }
}

var Size = {
  $$class: $$class
};

var Direction = {};

export {
  Size ,
  Direction ,
}
/* No side effect */
