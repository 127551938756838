// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Routes from "../../routes/Routes.res.js";
import * as DesignsNavigationStyle from "./DesignsNavigationStyle.res.js";
import * as Solid from "@heroicons/react/24/solid";
import * as Outline from "@heroicons/react/24/outline";

function DesignsNavigation(props) {
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Dashboard";
  return React.createElement("div", {
              className: DesignsNavigationStyle.container
            }, React.createElement("div", {
                  className: DesignsNavigationStyle.innerContainer
                }, React.createElement("div", {
                      className: DesignsNavigationStyle.logoContainer
                    }, React.createElement("img", {
                          className: "h-8 w-auto",
                          alt: "Your Company",
                          src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        })), React.createElement("nav", {
                      className: DesignsNavigationStyle.navContainer
                    }, React.createElement("ul", {
                          className: DesignsNavigationStyle.navList,
                          role: "list"
                        }, React.createElement("li", undefined, React.createElement("ul", {
                                  className: "-mx-2 space-y-1",
                                  role: "list"
                                }, React.createElement("li", undefined, React.createElement("a", {
                                          className: "Dashboard" === currentLink ? DesignsNavigationStyle.currentLink : DesignsNavigationStyle.link,
                                          href: Routes.Designs.index
                                        }, React.createElement((function (prim) {
                                                return Solid.HomeIcon.render(prim);
                                              }), {
                                              className: DesignsNavigationStyle.icon
                                            }), "Dashboard")), React.createElement("li", undefined, React.createElement("a", {
                                          className: "Palette" === currentLink ? DesignsNavigationStyle.currentLink : DesignsNavigationStyle.link,
                                          href: Routes.Designs.palette
                                        }, React.createElement((function (prim) {
                                                return Outline.EyeDropperIcon.render(prim);
                                              }), {
                                              className: DesignsNavigationStyle.icon
                                            }), "Palette")), React.createElement("li", undefined, React.createElement("a", {
                                          className: "Types" === currentLink ? DesignsNavigationStyle.currentLink : DesignsNavigationStyle.link,
                                          href: Routes.Designs.types
                                        }, React.createElement((function (prim) {
                                                return Outline.NewspaperIcon.render(prim);
                                              }), {
                                              className: DesignsNavigationStyle.icon
                                            }), "Types"))))))));
}

var Css;

var make = DesignsNavigation;

export {
  Css ,
  make ,
}
/* react Not a pure module */
