// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Routes from "../../routes/Routes.res.js";
import * as ReactIcons from "../../../bindings/ReactIcons.res.js";
import * as NavigationStyles from "./styles/NavigationStyles.res.js";
import * as NavigationMenuItem from "./components/menu-item/NavigationMenuItem.res.js";
import * as Outline from "@heroicons/react/24/outline";
import Rescript_on_rails_logoSvg from "../shared/images/rescript_on_rails_logo.svg";

var logo = Rescript_on_rails_logoSvg;

function $$Navigation(props) {
  var match = React.useState(function () {
        return false;
      });
  var setBookMenuOpen = match[1];
  var bookMenuOpen = match[0];
  return React.createElement("header", {
              className: NavigationStyles.header
            }, React.createElement("nav", {
                  className: NavigationStyles.navigation
                }, React.createElement("a", {
                      className: NavigationStyles.logoContainer,
                      href: "/"
                    }, React.createElement("img", {
                          className: NavigationStyles.logo,
                          alt: "ReScript on Rails Logo",
                          src: logo
                        }), "ReScript React on Rails Tuturial"), React.createElement("div", {
                      className: NavigationStyles.login
                    }, React.createElement(ReactIcons.Link.make, {
                          href: Routes.Author.linkedin,
                          icon: "CiLinkedin",
                          size: "XXL",
                          linkClassName: NavigationStyles.icon
                        }), React.createElement((function (prim) {
                            return Outline.BookOpenIcon.render(prim);
                          }), {
                          className: NavigationStyles.icon,
                          id: "bookMenu",
                          onClick: (function (param) {
                              setBookMenuOpen(function (_prev) {
                                    return !bookMenuOpen;
                                  });
                            })
                        }), bookMenuOpen ? React.createElement("div", {
                            "aria-orientation": "vertical",
                            "aria-labelledby": "bookMenu",
                            className: NavigationStyles.bookMenu,
                            role: "menu",
                            tabIndex: -1
                          }, React.createElement(NavigationMenuItem.make, {
                                href: Routes.Chapter.forward,
                                text: "Forward"
                              }), React.createElement(NavigationMenuItem.make, {
                                href: Routes.Chapter.show(1),
                                text: "Getting Started: Hello World"
                              }), React.createElement(NavigationMenuItem.make, {
                                href: Routes.Chapter.show(2),
                                text: "Hello World: ReScript"
                              }), React.createElement(NavigationMenuItem.make, {
                                href: Routes.Chapter.show(3),
                                text: "ReScript Flavored Stylesheets"
                              }), React.createElement(NavigationMenuItem.make, {
                                href: Routes.Chapter.show(4),
                                text: "Multicloud Deployment"
                              })) : null)));
}

var Css;

var MenuItem;

var make = $$Navigation;

export {
  Css ,
  MenuItem ,
  logo ,
  make ,
}
/* logo Not a pure module */
