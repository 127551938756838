// Generated by ReScript, PLEASE EDIT WITH CARE


var node = "18.16.0";

var nodeFull = "Node " + node;

var ruby = "3.3.1";

var rubyFull = "Ruby " + ruby;

var rails = "7.1.3.4";

var railsFull = "Rails " + rails;

var yarn = "4.2.1";

var yarnFull = "Yarn " + yarn;

var bundler = "2.5.11";

var bundleInstall = "bundle _" + bundler + "_ install";

var rescript = "11.1.0";

var rescriptFull = "ReScript " + rescript;

var Gemfile = {
  sproketsRails: "3.4.2",
  pg: "1.5.6",
  puma: "6.4.2",
  jbuilder: "2.12.0",
  bootsnap: "1.18.3",
  cssBundlingRails: "1.4.0",
  debug: "~> 1.7.1",
  webConsole: "4.2.1",
  capybara: "3.40.0",
  seleniumWebdriver: "4.20.1",
  shakapacker: "7.2.0",
  reactOnRails: "14.0.0",
  tailwindCSS: "2.6.1"
};

export {
  node ,
  nodeFull ,
  ruby ,
  rubyFull ,
  rails ,
  railsFull ,
  yarn ,
  yarnFull ,
  bundler ,
  bundleInstall ,
  rescript ,
  rescriptFull ,
  Gemfile ,
}
/* No side effect */
