// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Version from "../../../models/Version.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter01Code from "../components/Chapter01Code.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter01Listings from "../components/Chapter01Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Basic_railsPng from "../images/basic_rails.png";

var basicRails = Basic_railsPng;

function SectionBasicRails(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Basic Rails Application"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Start by creating a directory for following along. For example:"
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.mkdir
                }), React.createElement(ChapterParagraph.make, {
                  children: "Check that you are using the proper versions of Ruby, Node, and Yarn. \
        Your system output may be slightly diffrent based on your environment."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.versions
                }), React.createElement(ChapterParagraph.make, {
                  children: "If all the versions are correct, install Rails and Bundler, and create our new app."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.gemInstall
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Next, update your ", React.createElement(ChapterFile.make, {
                      text: "Gemfile"
                    }), ", which is written to avoid dependency conflicts. \
        You might take a different approach in a production application."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.simpleGemfiled)),
                  language: "Ruby",
                  children: Chapter01Code.gemfile1
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Run ", React.createElement(ChapterFile.make, {
                      text: Version.bundleInstall
                    }), " to update the ", React.createElement(ChapterFile.make, {
                      text: "Gemfile.lock"
                    }), " and commit. ", "Throughout the book we're going to maintain a fairly strict ", React.createElement(ChapterFile.make, {
                      text: "Gemfile"
                    }), " ", "to try to prevent conflicts between gem versions."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.bundleInstall
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "You should see the following page when you visit ", React.createElement(ChapterFile.make, {
                      text: "http://localhost:3000"
                    }), ":"), React.createElement(ChapterImage.make, {
                  id: "railsDefault",
                  caption: "Figure 1.1: A Basic Rails Application",
                  onClick: props.onClick,
                  imageType: "Preview",
                  image: basicRails
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.commitBasicRails
                }), React.createElement(ChapterParagraph.make, {
                  children: "If your application is working properly, go ahead and stop the Rails \
        server (CTRL C on a Mac)."
                }), React.createElement(ChapterParagraph.make, {
                  children: "One final bit of house cleaning. Assuming you're using Yarn version " + Version.yarn + "\n        , run the following to get the app setup for future JavaScript\n        installation."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.yarnInit
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Open ", React.createElement(ChapterFile.make, {
                      text: ".yarnrc.yml"
                    }), " ", "in your editor and add the a line to declare where node_modules\n        will reside."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.yarnYml)),
                  language: "YAML",
                  children: Chapter01Code.updateYarnRc
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.ignoreYarn)),
                  language: "Dockerfile",
                  children: Chapter01Code.ignoreYarn
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.commitYarnRc
                }));
}

var Section;

var Title;

var P;

var Code;

var Listings;

var $$File;

var make = SectionBasicRails;

export {
  basicRails ,
  Section ,
  Title ,
  P ,
  Code ,
  Listings ,
  $$File ,
  make ,
}
/* basicRails Not a pure module */
