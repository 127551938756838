// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterPreview from "../../components/ChapterPreview.res.js";
import * as PreviewHelloWorldStyles from "./PreviewHelloWorldStyles.res.js";

function PreviewHelloWorld(props) {
  return React.createElement(ChapterPreview.make, {
              children: React.createElement("div", {
                    className: PreviewHelloWorldStyles.container
                  }, "Hello World!")
            });
}

var Css;

var make = PreviewHelloWorld;

export {
  Css ,
  make ,
}
/* react Not a pure module */
