// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionOverview(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Overview"
                }), React.createElement(ChapterParagraph.make, {
                  children: "How many tutorials start with \"Hello World\"?\
        Just about every one.\
        Sorry to disappoint you, but this tutorial is no different.\
        If it ain't broke, why fix it? Am I right?"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Starting with the classic \"Hello World\" Ruby on Rails application \
        keeps your focus on the core goal of this chapter, to integrate \
        React with Rails. We will get to ReScript later; I promise. \
        Instead of a static \"Hello World\" page (How boring!?), you will \
        build a dynamic view that leverages React components which update \
        the DOM (Document Object Model) in real time as users interact with it."
                }), React.createElement(ChapterParagraph.make, {
                  children: "Integrating React with a Rails application is relatively straight \
        forward using open source tools. But, there are pitfalls, \
        which this book helps you avoid. Each step is covered in detail, along with a \
        little history of Rails and JavaScript sprinkled throughout."
                }), React.createElement(ChapterParagraph.make, {
                  children: "You will walk away with the foundation you need to set up any Rails application with React. \
        Maybe that is all you came for, afterall? Hopefully not. Ideally, you will be enticed enough \
        to persist to the end."
                }), React.createElement(ChapterBox.make, {
                  number: "1.1",
                  title: "Getting Started: Goals",
                  children: null
                }, React.createElement("p", undefined, "In this chapter we will accomplish the following:"), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "Create a basic Rails application with a static Hello World view."), React.createElement("li", undefined, "Introduce the MVCC (Model-View-Component-Controller) architecture."), React.createElement("li", undefined, "Install and setup React on Rails with Shakapacker."), React.createElement("li", undefined, "Build on our Hello World view with an integrated HelloWorld React component."))));
}

var Section;

var Title;

var P;

var Box;

var make = SectionOverview;

export {
  Section ,
  Title ,
  P ,
  Box ,
  make ,
}
/* react Not a pure module */
