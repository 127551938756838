// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Arrow from "../../../../styleguide/icons/Arrow.res.js";
import * as React from "react";
import * as Chapter from "../../../../models/Chapter.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterLink from "../../ChapterLink.res.js";
import * as ChapterNavigationStyles from "../styles/ChapterNavigationStyles.res.js";

function ChapterNavigation(props) {
  var next = props.next;
  var previous = props.previous;
  return React.createElement("div", {
              className: previous !== undefined ? (
                  next !== undefined ? ChapterNavigationStyles.fullContainer : ChapterNavigationStyles.leftContainer
                ) : (
                  next !== undefined ? ChapterNavigationStyles.rightContainer : "hidden"
                )
            }, previous !== undefined ? React.createElement("div", {
                    className: ChapterNavigationStyles.arrowContainer
                  }, React.createElement(ChapterLink.make, {
                        className: Cx.cx([ChapterNavigationStyles.arrowLinkReverse]),
                        children: Caml_option.some(React.createElement(Arrow.make, {
                                  size: "XS"
                                })),
                        href: Chapter.path(previous),
                        blank: false,
                        text: Chapter.fullTitle(previous)
                      })) : null, next !== undefined ? React.createElement("div", {
                    className: ChapterNavigationStyles.arrowContainer
                  }, React.createElement(ChapterLink.make, {
                        className: ChapterNavigationStyles.arrowLink,
                        children: Caml_option.some(React.createElement(Arrow.make, {
                                  direction: "Right",
                                  size: "XS"
                                })),
                        href: Chapter.path(next),
                        blank: false,
                        text: Chapter.fullTitle(next)
                      })) : null);
}

var Css;

var make = ChapterNavigation;

export {
  Css ,
  make ,
}
/* Arrow Not a pure module */
