// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter04Listings from "../components/Chapter04Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";

function SectionOverview04(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Overview"
                }), React.createElement(ChapterParagraph.make, {
                  children: React.createElement(ChapterFile.make, {
                        text: "WARNING: This chapter is in active development and some steps may be missing or\n          updated in the near future. More coming soon!"
                      })
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "In ", React.createElement(ChapterLink.make, {
                      href: "/chapters/1",
                      text: "Chapter 1"
                    }), ", we went with a common starting point, creating a\n        \"Hello World\" page. Now, we're going to deviate from traditional\n        practice. Instead of deploying to Heroku, AWS or GCP, we're\n        going to use a newer service called ", React.createElement(ChapterLink.make, {
                      href: "https://controlplane.com/",
                      text: "Control Plane"
                    }), ", which allows you to deploy to multiple clouds and regions at once."), React.createElement(ChapterParagraph.make, {
                  children: "This chapter is entirely optional. Signing up for Control Plane\n        will initially cost money, but you'll learn how to scale workloads\n        to zero when not in use. The cost will likely be less than a $1 / day\n        while your workloads are running for this tutorial."
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, React.createElement(ChapterLink.make, {
                      href: "https://www.datacenters.com",
                      text: "Datacenters.com "
                    }), "was an early beta adopter of Control Plane after\n        deploying to both Heroku and AWS directly for years.\n        Now with the proper setup, our production hosting costs have dropped by\n        roughly 80%. And here's the amazing part - we're still deploying\n        to AWS, but through a Global Virtual Cloud (GVC), instead of through\n        the AWS Console! We've also set up deployments to work seemlessly\n        with our full CI/CD process."), React.createElement(ChapterParagraph.make, {
                  children: "I imagine that with a little determination, if you really want to deploy\n        to Heroku or AWS, you could figure it out. Maybe in a future edition\n        I'll compliment this chapter with additional deployment options.\n        But, I'd like to show you something new that you might not have seen before.\n        And, just maybe, you'll find an alternative deployment option that will save you money\n        on hosting and DevOps."
                }), React.createElement(ChapterBox.make, {
                  number: Chapter04Listings.Box.whatWellLearn.number,
                  title: Chapter04Listings.Box.whatWellLearn.text,
                  children: null
                }, React.createElement("p", undefined, "In this chapter we will learn how to use the following benefist from Control Plane:"), React.createElement("ol", {
                      className: ChapterStyles.Box.numberedList
                    }, React.createElement("li", undefined, "Create a Global Virtual Cloud (GVC), that can be deployed to any region of\n            the world provided by AWS, GCP and Azure simulatneously!"), React.createElement("li", undefined, "Create separate workloads for each of the important Rails operations with\n            dedicated CPU and Memory."), React.createElement("li", undefined, "Use CapacityAI from Contorl Plane to automatically scale CPU / Memory based\n            on requests per second to your site."), React.createElement("li", undefined, "Create a stateful workload to host your PostgreSQL database with a dedicated\n            volume for storage."), React.createElement("li", undefined, "Install and use the Control Plane CLI (@controlplane/cli) along side the\n            Control Plane Flow gem to orchestrate deployments."), React.createElement("li", undefined, "Containerize and deploy your tutorial application to Control Plane."), React.createElement("li", undefined, "Use replicas to stablize your environment and ensure consistent uptime."), React.createElement("li", undefined, "Automatically scale your workloads to zero when not in use, which is\n            especially useful for staging / test sites."))));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var Listings;

var make = SectionOverview04;

export {
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  Listings ,
  make ,
}
/* react Not a pure module */
