// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Routes from "../../../routes/Routes.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter04Code from "../components/Chapter04Code.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter04Listings from "../components/Chapter04Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Cpln_cli_loginPng from "../images/cpln_cli_login.png";
import Cpln_cli_successPng from "../images/cpln_cli_success.png";

var cplnCliLogin = Cpln_cli_loginPng;

var cplnCliSuccess = Cpln_cli_successPng;

function SectionCplnCli(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Control Plane CLI Setup"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "In order to interact with Control Plane, you will need to install the ", React.createElement(ChapterLink.make, {
                      href: Routes.ControlPlane.Docs.cli,
                      text: "Control Plance CLI "
                    }), "(Command Line Interface). The CLI is a powerful tool that allows you to interact with\n        Control Plane from the command line."), React.createElement(ChapterSectionTitle.make, {
                  text: "Install the CLI",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "If you're on a Mac, you can install the CLI using the ", React.createElement(ChapterLink.make, {
                      href: Routes.ControlPlane.Docs.cliHomebrew,
                      text: "Homebrew Tap"
                    }), ". ", "If you don't have Homebrew installed, you can install it with npm."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cplnNpm)),
                  language: "Console",
                  children: Chapter04Code.cplnNpm
                }), React.createElement(ChapterParagraph.make, {
                  children: "Once signed in, this will give you access to Control Plane. Combined with the Control Plane\n        Flow gem, you'll have everything you need to deploy your apps. Here's a list of\n        commands available with the package."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cplnHelp)),
                  language: "Console",
                  children: Chapter04Code.cplnHelp
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Login with the CLI",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Now, log into your Control Plane account and start using the CLI to interact with your\n        Control Plane resources."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cplnLogin)),
                  language: "Console",
                  children: Chapter04Code.cplnLogin
                }), React.createElement(ChapterParagraph.make, {
                  children: "The command will open your default browser and ask you to login to your Control Plane account.\n        This happens, even if you're logged into Control Plane in another window. Be sure to choose\n        the same login method you used in the beginning of the chapter."
                }), React.createElement(ChapterImage.make, {
                  id: "cplnCliLogin",
                  caption: "Figure 4.6: Control Plane CLI Login.",
                  className: ChapterStyles.$$Image.imageHalf,
                  imageType: "Static",
                  image: cplnCliLogin
                }), React.createElement(ChapterParagraph.make, {
                  children: "If you're successful, you'll see a message that you're logged in both in the browser\n        and your terminal. You can now start using the Control Plane CLI to interact with\n        your Control Plane resources."
                }), React.createElement(ChapterImage.make, {
                  id: "cplnCliSuccess",
                  caption: "Figure 4.7: Control Plane CLI Login Success.",
                  className: ChapterStyles.$$Image.imageHalf,
                  imageType: "Static",
                  image: cplnCliSuccess
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cplnCliSuccess)),
                  language: "Console",
                  children: Chapter04Code.cplnCliSuccess
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Image Repository Login",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Finally, select the Org image respository to push your app."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter04Listings.cplnImageLogin)),
                  language: "Console",
                  children: Chapter04Code.cplnImageLogin
                }), React.createElement(ChapterParagraph.make, {
                  children: "We're getting closer to deploying our app. Next, we'll install the\n        Control Plane Flow gem."
                }));
}

var Section;

var Title;

var P;

var Box;

var Link;

var $$File;

var $$Image;

var Code;

var Listings;

var Css;

var make = SectionCplnCli;

export {
  cplnCliLogin ,
  cplnCliSuccess ,
  Section ,
  Title ,
  P ,
  Box ,
  Link ,
  $$File ,
  $$Image ,
  Code ,
  Listings ,
  Css ,
  make ,
}
/* cplnCliLogin Not a pure module */
