// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SectionGvc from "./sections/SectionGvc.res.js";
import * as SectionCplnCli from "./sections/SectionCplnCli.res.js";
import * as SectionCplnFlow from "./sections/SectionCplnFlow.res.js";
import * as SectionCplnSteps from "./sections/SectionCplnSteps.res.js";
import * as SectionCplnDeploy from "./sections/SectionCplnDeploy.res.js";
import * as SectionCplnSignUp from "./sections/SectionCplnSignUp.res.js";
import * as SectionOverview04 from "./sections/SectionOverview04.res.js";
import * as SectionInstallDocker from "./sections/SectionInstallDocker.res.js";

function Chapter04(props) {
  return React.createElement(React.Fragment, {}, React.createElement(SectionOverview04.make, {}), React.createElement(SectionGvc.make, {}), React.createElement(SectionCplnSteps.make, {}), React.createElement(SectionInstallDocker.make, {}), React.createElement(SectionCplnSignUp.make, {}), React.createElement(SectionCplnCli.make, {}), React.createElement(SectionCplnFlow.make, {}), React.createElement(SectionCplnDeploy.make, {}));
}

var make = Chapter04;

export {
  make ,
}
/* react Not a pure module */
