// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function reducer(_state, action) {
  return {
          name: action._0
        };
}

function eventTargetValue($$event) {
  $$event.preventDefault();
  return $$event.target.value;
}

function HelloWorld$default(props) {
  var match = React.useReducer(reducer, {
        name: props.name
      });
  var dispatch = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "text-red-50"
            }, React.createElement("h3", undefined, "Hello, " + state.name + "!"), React.createElement("hr", undefined), React.createElement("form", undefined, React.createElement("label", {
                      htmlFor: "name"
                    }, "Say hello to blah blah: ", React.createElement("input", {
                          id: "name",
                          type: "text",
                          value: state.name,
                          onChange: (function ($$event) {
                              dispatch({
                                    TAG: "UpdateName",
                                    _0: eventTargetValue($$event)
                                  });
                            })
                        }))));
}

var $$default = HelloWorld$default;

export {
  reducer ,
  eventTargetValue ,
  $$default as default,
}
/* react Not a pure module */
