// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "block",
      "m-2",
      "h-screen",
      "w-screen",
      "bg-white",
      "font-['Open_Sans']"
    ]);

export {
  container ,
}
/* container Not a pure module */
