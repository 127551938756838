// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FakerLorem from "../../../bindings/faker/FakerLorem.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DesignsText from "../styles/DesignsText.res.js";
import * as DesignsHeader from "../styles/DesignsHeader.res.js";
import * as DesignsLayout from "../layout/DesignsLayout.res.js";
import * as DesignsContent from "../layout/components/DesignsContent.res.js";
import * as DesignTypesExample from "./components/DesignTypesExample.res.js";
import * as DesignsTypesStyles from "./DesignsTypesStyles.res.js";

function DesignsTypes$default(props) {
  var sampleText = FakerLorem.sentence(4);
  return React.createElement(DesignsLayout.make, {
              currentLink: "Types",
              children: Caml_option.some(React.createElement(DesignsContent.make, {
                        children: null
                      }, React.createElement("h1", {
                            className: DesignsHeader.h1
                          }, "Types"), React.createElement("div", {
                            className: DesignsTypesStyles.section
                          }, React.createElement("h2", {
                                className: DesignsHeader.Section.h2
                              }, "Default Headers"), React.createElement("div", {
                                className: DesignsTypesStyles.examples
                              }, React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsHeader.h4,
                                    title: "h4"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsHeader.h3,
                                    title: "h3"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsHeader.h2,
                                    title: "h2"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsHeader.h1,
                                    title: "h1"
                                  }))), React.createElement("div", {
                            className: DesignsTypesStyles.section
                          }, React.createElement("h2", {
                                className: DesignsHeader.Section.h2
                              }, "Default Text"), React.createElement("div", {
                                className: DesignsTypesStyles.examples
                              }, React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.xs,
                                    title: "xs"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.sm,
                                    title: "sm"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.base,
                                    title: "base"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.lg,
                                    title: "lg"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.xl,
                                    title: "xl"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.xl2,
                                    title: "2xl"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.xl3,
                                    title: "3xl"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.xl4,
                                    title: "4xl"
                                  }), React.createElement(DesignTypesExample.make, {
                                    text: sampleText,
                                    style: DesignsText.xl5,
                                    title: "5xl"
                                  })))))
            });
}

var Css;

var Example;

var $$default = DesignsTypes$default;

export {
  Css ,
  Example ,
  $$default as default,
}
/* react Not a pure module */
