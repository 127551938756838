// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Pulse from "../../styleguide/pulse/Pulse.res.js";
import * as React from "react";
import * as Routes from "../../routes/Routes.res.js";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";
import * as ChapterNavigation from "./chapter-navigation/components/ChapterNavigation.res.js";
import * as NavigationMenuItem from "../../views/navigation/components/menu-item/NavigationMenuItem.res.js";
import * as Outline from "@heroicons/react/24/outline";

function reducer(state, action) {
  return {
          bookMenuOpen: !state.bookMenuOpen
        };
}

function ChapterContainer(props) {
  var match = React.useReducer(reducer, {
        bookMenuOpen: false
      });
  var dispatch = match[1];
  return React.createElement("div", {
              className: ChapterStyles.container
            }, React.createElement("div", undefined, React.createElement((function (prim) {
                        return Outline.BookOpenIcon.render(prim);
                      }), {
                      className: ChapterStyles.bookIcon,
                      id: "bookMenu",
                      onClick: (function (param) {
                          dispatch("SetBookMenuOpen");
                        })
                    }), match[0].bookMenuOpen ? React.createElement("div", {
                        "aria-orientation": "vertical",
                        "aria-labelledby": "bookMenu",
                        className: ChapterStyles.bookMenu,
                        role: "menu",
                        tabIndex: -1
                      }, React.createElement(NavigationMenuItem.make, {
                            href: "/",
                            text: "Home"
                          }), React.createElement(NavigationMenuItem.make, {
                            href: Routes.Chapter.forward,
                            text: "Forward"
                          }), React.createElement(NavigationMenuItem.make, {
                            href: Routes.Chapter.show(1),
                            text: "Getting Started: Hello World"
                          }), React.createElement(NavigationMenuItem.make, {
                            href: Routes.Chapter.show(2),
                            text: "Hello World: ReScript"
                          }), React.createElement(NavigationMenuItem.make, {
                            href: Routes.Chapter.show(3),
                            text: "ReScript Flavored Stylesheets"
                          }), React.createElement(NavigationMenuItem.make, {
                            href: Routes.Chapter.show(4),
                            text: "Multicloud Deployment"
                          })) : null), React.createElement("div", {
                  className: "m-5"
                }, React.createElement(Pulse.make, {})), props.children, React.createElement(ChapterNavigation.make, {
                  previous: props.previous,
                  next: props.next
                }));
}

var Css;

var make = ChapterContainer;

export {
  Css ,
  reducer ,
  make ,
}
/* Pulse Not a pure module */
