// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Ci from "react-icons/ci";

var CiLinkedin = {};

function iconToComponent(icon) {
  return React.createElement(Ci.CiLinkedin, {});
}

function sizeToClass(size) {
  switch (size) {
    case "XS" :
        return "text-xs";
    case "Small" :
        return "text-sm";
    case "Base" :
        return "text-base";
    case "Large" :
        return "text-lg";
    case "XL" :
        return "text-xl";
    case "XXL" :
        return "text-2xl";
    case "XXXL" :
        return "text-3xl";
    
  }
}

function ReactIcons$Icon(props) {
  var className = props.className;
  var __size = props.size;
  var size = __size !== undefined ? __size : "Base";
  var $$class = className !== undefined ? Cx.cx([
          sizeToClass(size),
          className
        ]) : sizeToClass(size);
  return React.createElement("div", {
              className: $$class
            }, React.createElement(Ci.CiLinkedin, {}));
}

var Icon = {
  make: ReactIcons$Icon
};

function ReactIcons$Link(props) {
  var __linkClassName = props.linkClassName;
  var __size = props.size;
  var size = __size !== undefined ? __size : "Base";
  var linkClassName = __linkClassName !== undefined ? __linkClassName : "";
  return React.createElement("a", {
              className: linkClassName,
              href: props.href
            }, React.createElement(ReactIcons$Icon, {
                  icon: props.icon,
                  size: size,
                  className: props.className
                }));
}

var Link = {
  make: ReactIcons$Link
};

export {
  CiLinkedin ,
  iconToComponent ,
  sizeToClass ,
  Icon ,
  Link ,
}
/* react Not a pure module */
