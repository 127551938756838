// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterPage(props) {
  return React.createElement("div", {
              className: ChapterStyles.Page.container
            }, props.children);
}

var Css;

var make = ChapterPage;

export {
  Css ,
  make ,
}
/* react Not a pure module */
