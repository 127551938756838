// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Svg from "./Svg.res.js";
import * as React from "react";

function Arrow(props) {
  var __size = props.size;
  var __direction = props.direction;
  var direction = __direction !== undefined ? __direction : "Left";
  var size = __size !== undefined ? __size : "XS";
  if (direction === "Left") {
    return React.createElement("svg", {
                className: Svg.Size.$$class(size),
                fill: "none",
                stroke: "currentColor",
                strokeWidth: "1.5",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }, React.createElement("path", {
                    d: "M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18",
                    strokeLinecap: "round",
                    strokeLinejoin: "round"
                  }));
  } else {
    return React.createElement("svg", {
                className: Svg.Size.$$class(size),
                fill: "none",
                stroke: "currentColor",
                strokeWidth: "1.5",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg"
              }, React.createElement("path", {
                    d: "M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3",
                    strokeLinecap: "round",
                    strokeLinejoin: "round"
                  }));
  }
}

var make = Arrow;

export {
  make ,
}
/* react Not a pure module */
