// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DesignsLayout from "../layout/DesignsLayout.res.js";

function DesignsIndex$default(props) {
  return React.createElement(DesignsLayout.make, {
              children: Caml_option.some(React.createElement("div", undefined, "Hello"))
            });
}

var $$default = DesignsIndex$default;

export {
  $$default as default,
}
/* react Not a pure module */
