// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var chapter = Json_Decode$JsonCombinators.object(function (field) {
      return {
              number: field.required("number", Json_Decode$JsonCombinators.$$int),
              title: field.required("title", Json_Decode$JsonCombinators.string)
            };
    });

var Decode = {
  chapter: chapter
};

var pagination = Json_Decode$JsonCombinators.object(function (field) {
      return {
              previous: field.optional("previous", chapter),
              current: field.required("current", chapter),
              next: field.optional("next", chapter)
            };
    });

var Decode$1 = {
  pagination: pagination
};

var Pagination = {
  Decode: Decode$1
};

function fullTitle(chapter) {
  return "Chapter " + chapter.number.toString() + ": " + chapter.title;
}

function path(chapter) {
  return "/chapters/" + chapter.number.toString();
}

export {
  Decode ,
  Pagination ,
  fullTitle ,
  path ,
}
/* chapter Not a pure module */
