// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter02Code from "../components/Chapter02Code.res.js";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter02Listings from "../components/Chapter02Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import Rescript_hello_worldPng from "../images/rescript_hello_world.png";
import Hello_world_react_typingPng from "../images/hello_world_react_typing.png";

var rescriptHelloWorld = Rescript_hello_worldPng;

var helloWorldReactTyping = Hello_world_react_typingPng;

function SectionRescriptInstallation(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "ReScript Installation"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "First, we need to set up the app with the required ReScript packages\n        and ensure that our app builds and loads our files properly. ", "These commands will update your ", React.createElement(ChapterFile.make, {
                      text: "package.json"
                    }), " ", "file as needed."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter02Code.yarnAddRescript
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Next, we need to add a ", React.createElement(ChapterFile.make, {
                      text: "rescript.json"
                    }), " ", "file for managing our new packages."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter02Code.bsConfig
                }), React.createElement(ChapterParagraph.make, {
                  children: "Add the following to your new file."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.bsConfig)),
                  language: "JSON",
                  children: Chapter02Code.intitialBsConfig
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "The ", React.createElement(ChapterFile.make, {
                      text: "\"dir\""
                    }), " ", "ensures that when compiling our files, that ReScript looks for files only in the ", React.createElement(ChapterFile.make, {
                      text: "app/javascript"
                    }), " ", "folder and subfolders."), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Next, create a ", React.createElement(ChapterFile.make, {
                      text: "HelloWorld.res"
                    }), " ", "file in your ", React.createElement(ChapterFile.make, {
                      text: "app/javascript/bundles"
                    }), " ", "folder."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter02Code.helloRes
                }), React.createElement(ChapterParagraph.make, {
                  children: "Add the following code to your file."
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.helloWorldRes)),
                  language: "ReScript",
                  children: Chapter02Code.helloResFile
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Next, we need to point ", React.createElement(ChapterFile.make, {
                      text: "application.js"
                    }), " ", "and ", React.createElement(ChapterFile.make, {
                      text: "server-bundle.js"
                    }), " ", "to our new code."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.applicationJs)),
                  language: "JavaScript",
                  children: Chapter02Code.bundleUpdates
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Add a few lines to our ", React.createElement(ChapterFile.make, {
                      text: ".gitignore"
                    }), " ", "file at the end."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.gitignore)),
                  language: "Dockerfile",
                  children: Chapter02Code.gitignore
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Finally, add a line to your ", React.createElement(ChapterFile.make, {
                      text: "Profile.dev"
                    }), " ", "file to build the ", React.createElement(ChapterFile.make, {
                      text: ".res.js"
                    }), " ", "file (\"bs\" is short for BuckleScript) for the server."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.procfileDev)),
                  language: "Dockerfile",
                  children: Chapter02Code.procfileDev
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "One last bit of housecleaning. Update your ", React.createElement(ChapterFile.make, {
                      text: "shakapacker.yml"
                    }), " ", "file to include the following to prevent a deprecation warning:"), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.shakapackerYml)),
                  language: "YAML",
                  children: Chapter02Code.shakapackerYml
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Start your Rails server again and you should see the new page generated\n        by the ", React.createElement(ChapterFile.make, {
                      text: "HelloWorld.res"
                    }), " ", "file loading on the screen:"), React.createElement(ChapterImage.make, {
                  id: "railsDefault",
                  caption: "Figure 2.1: ReScript Hello World!",
                  onClick: props.onClick,
                  imageType: "Preview",
                  image: rescriptHelloWorld
                }), React.createElement(ChapterParagraph.make, {
                  children: "Congratulations! You now have a fully functioning ReScript on Rails\n        application."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Missing Styles?",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "But wait, the keen observer may have noticed that the green style in missing from\n        our label. No problem. Go ahead and add the following to ", React.createElement(ChapterFile.make, {
                      text: "app/assets/stylesheets/application.css"
                    }), ":"), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter02Listings.brightStyle)),
                  language: "CSS",
                  children: Chapter02Code.brightStyle
                }), React.createElement(ChapterParagraph.make, {
                  children: "Our styles are now back in action."
                }), React.createElement(ChapterImage.make, {
                  id: "typeHelloReact",
                  caption: "Figure 1.5: The view updates as you type.",
                  imageType: "Static",
                  image: helloWorldReactTyping
                }), React.createElement(ChapterParagraph.make, {
                  children: "At this point, you could move forward using the Rails asset pipeline to style your application.\n        In the next chapter, we'll install TailwindCSS. That will give us the ability to\n        use ReScript to style our app instead."
                }), React.createElement(ChapterParagraph.make, {
                  children: "Lets commit our code before moving to the next chapter."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter02Code.commitRescript
                }));
}

var Section;

var Title;

var P;

var Code;

var $$File;

var Listings;

var make = SectionRescriptInstallation;

export {
  rescriptHelloWorld ,
  helloWorldReactTyping ,
  Section ,
  Title ,
  P ,
  Code ,
  $$File ,
  Listings ,
  make ,
}
/* rescriptHelloWorld Not a pure module */
