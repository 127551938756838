// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";

var $$class = Cx.cx([
      "rounded-md",
      "font-semibold",
      "shadow-sm",
      "focus-visible:outline",
      "focus-visible:outline-2",
      "focus-visible:outline-offset-2"
    ]);

function classColor(color) {
  return Cx.cx([
              "text-white",
              "bg-red-vivid-500",
              "hover:bg-red-vivid-400",
              "focus-visible:outline-red-vivid-600"
            ]);
}

function classSize(size) {
  return Cx.cx([
              "px-6",
              "py-4",
              "text-xl"
            ]);
}

function Button(props) {
  var __size = props.size;
  var __color = props.color;
  var href = props.href;
  var text = props.text;
  var color = __color !== undefined ? __color : "Red";
  var size = __size !== undefined ? __size : "XL";
  var className = Cx.cx([
        $$class,
        classColor(color),
        classSize(size)
      ]);
  if (href !== undefined) {
    return React.createElement("a", {
                className: className,
                href: href,
                type: "button"
              }, text);
  } else {
    return React.createElement("button", {
                className: className,
                type: "button",
                onClick: props.onClick
              }, text);
  }
}

var make = Button;

export {
  $$class ,
  classColor ,
  classSize ,
  make ,
}
/* class Not a pure module */
