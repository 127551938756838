// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Info from "../../../styleguide/icons/Info.res.js";
import * as React from "react";
import * as ChapterBox from "../../components/ChapterBox.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChapterFile from "../../components/ChapterFile.res.js";
import * as ChapterLink from "../../components/ChapterLink.res.js";
import * as ChapterImage from "../../components/ChapterImage.res.js";
import * as ListingTitle from "../../../models/ListingTitle.res.js";
import * as Chapter01Code from "../components/Chapter01Code.res.js";
import * as ChapterStyles from "../../styles/ChapterStyles.res.js";
import * as ReactTooltip from "react-tooltip";
import * as ChapterSection from "../../components/ChapterSection.res.js";
import * as ChapterCodeBlock from "../../components/ChapterCodeBlock.res.js";
import * as ChapterParagraph from "../../components/ChapterParagraph.res.js";
import * as Chapter01Listings from "../components/Chapter01Listings.res.js";
import * as ChapterSectionTitle from "../../components/ChapterSectionTitle.res.js";
import * as ReactA11yFootnotes from "react-a11y-footnotes";
import Hello_worldPng from "../images/hello_world.png";
import Mvc_schematicPng from "../images/mvc_schematic.png";

var mvcSchematic = Mvc_schematicPng;

var helloWorld = Hello_worldPng;

function SectionHelloWorld(props) {
  return React.createElement(ChapterSection.make, {
              children: null
            }, React.createElement(ChapterSectionTitle.make, {
                  text: "Hello World"
                }), React.createElement(ChapterParagraph.make, {
                  children: "Now, we are ready to create our basic “Hello World” application."
                }), React.createElement(ChapterSectionTitle.make, {
                  text: "Model-View-Contoller (MVC)",
                  titleType: "Sub"
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, React.createElement(ReactA11yFootnotes.FootnoteRef, {
                      description: React.createElement(React.Fragment, {}, "Michael Hartl (May 19, 2022). ", React.createElement(ChapterLink.make, {
                                href: "https://www.learnenough.com/ruby-on-rails-7th-edition-tutorial#sec-mvc",
                                text: "Ruby on Rails Tutorial"
                              })),
                      children: "As discussed in the Ruby on Rails Tutorial, Rails uses the Model-View-Controller \
            (MVC) architecture."
                    }), " ", "To improve developer happiness and engender a more maintainable \
          code, Rails tries to enforce this web paradigm."), React.createElement(ChapterImage.make, {
                  id: "mvcSchematic",
                  caption: "Figure 1.2: Model-View-Controller by Michael Hartl, Ruby on Rails Tutorial.",
                  className: ChapterStyles.$$Image.imageHalf,
                  image: mvcSchematic
                }), React.createElement(ChapterBox.make, {
                  number: "1.3",
                  title: "How the Rails MVC Works, by Michael Hartl",
                  children: null
                }, React.createElement("div", undefined, "When interacting with a Rails application, a browser sends a request, which \
            is received by a web server and passed on to a Rails controller, which is in \
            charge of what to do next. In some cases, the controller will immediately render \
            a view, which is a template that gets converted to HTML and sent back to the browser. \
            More commonly for dynamic sites, the controller interacts with a model, which is a \
            Ruby object that represents an element of the site (such as a user) and is in charge \
            of communicating with the database. After invoking the model, the controller then \
            renders the view and returns the complete web page to the browser as HTML."), React.createElement("div", {
                      className: "flex justify-end"
                    }, React.createElement("a", {
                          className: "railsMvc"
                        }, React.createElement(Info.make, {})), React.createElement(ReactTooltip.Tooltip, {
                          className: "bg-white",
                          variant: "info",
                          clickable: true,
                          anchorSelect: ".railsMvc",
                          place: "left",
                          children: null
                        }, "Michael Hartl (May 19, 2022). ", React.createElement("a", {
                              className: "font-medium hover:text-purple-600",
                              href: "https://www.learnenough.com/ruby-on-rails-7th-edition-tutorial#sec-mvc",
                              target: "_blank"
                            }, "Ruby on Rails Tutorial")))), React.createElement(ChapterParagraph.make, {
                  children: "To get our first page going, we will need a corresponding controller and view for our \
          new page. At the moment we will not interact with a model. Instead, we will serve \
          a static page."
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Rails ships with a lot of cool tools like ", React.createElement(ChapterFile.make, {
                      text: "rails generate"
                    }), ". For now, we will take things slowly and create what we need manually. \
          Create a ", React.createElement(ChapterFile.make, {
                      text: "pages_controller.rb"
                    }), " ", "file with the following code."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.pagesController
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.basicPagesController)),
                  language: "Ruby",
                  children: Chapter01Code.pagesControllerCode
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Next, add the ", React.createElement(ChapterFile.make, {
                      text: "hello_world.html.erb"
                    }), " ", "view."), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.helloWorldCreate
                }), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.basicHelloWorld)),
                  language: "ERB",
                  children: Chapter01Code.helloWorldCode
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Finally, add the hello_world page as the root view in our ", React.createElement(ChapterFile.make, {
                      text: "routes.rb"
                    }), " ", "file."), React.createElement(ChapterCodeBlock.make, {
                  title: Caml_option.some(ListingTitle.toTitle(Chapter01Listings.routes)),
                  language: "Ruby",
                  children: Chapter01Code.helloRoutes
                }), React.createElement(ChapterParagraph.make, {
                  children: null
                }, "Go ahead and spin up ", React.createElement(ChapterFile.make, {
                      text: "$ rails server"
                    }), " ", "to see what you have accomplished so far."), React.createElement(ChapterImage.make, {
                  id: "helloWorld",
                  caption: "Figure 1.2: Hello world home page.",
                  onClick: props.onClick,
                  imageType: "Preview",
                  image: helloWorld
                }), React.createElement(ChapterParagraph.make, {
                  children: "If everything looks good, commit your code."
                }), React.createElement(ChapterCodeBlock.make, {
                  language: "Console",
                  children: Chapter01Code.commitHelloWorld
                }));
}

var Section;

var Title;

var P;

var Code;

var Link;

var Listings;

var $$File;

var make = SectionHelloWorld;

export {
  mvcSchematic ,
  helloWorld ,
  Section ,
  Title ,
  P ,
  Code ,
  Link ,
  Listings ,
  $$File ,
  make ,
}
/* mvcSchematic Not a pure module */
