// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ChapterStyles from "../styles/ChapterStyles.res.js";

function ChapterSectionTitle(props) {
  var __titleType = props.titleType;
  var text = props.text;
  var titleType = __titleType !== undefined ? __titleType : "Main";
  switch (titleType) {
    case "Main" :
        return React.createElement("h2", {
                    className: ChapterStyles.Page.sectionTitle,
                    id: "#" + text
                  }, text);
    case "Sub" :
        return React.createElement("h3", {
                    className: ChapterStyles.Page.subTitle,
                    id: "#" + text
                  }, text);
    case "Mini" :
        return React.createElement("h4", {
                    className: ChapterStyles.Page.miniTitle,
                    id: "#" + text
                  }, text);
    
  }
}

var Css;

var make = ChapterSectionTitle;

export {
  Css ,
  make ,
}
/* react Not a pure module */
