// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Scroll from "../../../bindings/Scroll.res.js";
import * as Chapter01 from "../chapter_01/Chapter01.res.js";
import * as Chapter02 from "../chapter_02/Chapter02.res.js";
import * as Chapter03 from "../chapter_03/Chapter03.res.js";
import * as Chapter04 from "../chapter_04/Chapter04.res.js";
import * as ChapterPage from "../components/ChapterPage.res.js";
import * as ChapterTypeIt from "../components/ChapterTypeIt.res.js";
import * as FramerMotion from "framer-motion";
import * as PreviewBrowser from "../../models/PreviewBrowser.res.js";
import * as ChapterContainer from "../components/ChapterContainer.res.js";
import * as PreviewHelloWorld from "../chapter_01/previews/PreviewHelloWorld.res.js";
import * as PreviewNewHomeColor from "../chapter_03/previews/PreviewNewHomeColor.res.js";
import * as PreviewRailsDefault from "../chapter_01/previews/PreviewRailsDefault.res.js";
import * as ReactA11yFootnotes from "react-a11y-footnotes";
import * as PreviewNewHomeInitial from "../chapter_03/previews/PreviewNewHomeInitial.res.js";
import * as PreviewHelloWorldReact from "../chapter_01/previews/PreviewHelloWorldReact.res.js";
import * as PreviewRescriptHelloWorld from "../chapter_02/previews/PreviewRescriptHelloWorld.res.js";
import * as PreviewRescriptHelloWorldTailwind from "../chapter_03/previews/PreviewRescriptHelloWorldTailwind.res.js";

function reducer(state, action) {
  if (action.TAG !== "ToggleView") {
    return {
            view: state.view,
            preview: state.preview,
            scrollPosition: action._0
          };
  }
  var preview = action._0;
  if (preview !== undefined) {
    return {
            view: "Preview",
            preview: preview,
            scrollPosition: state.scrollPosition
          };
  } else {
    return {
            view: "Chapter",
            preview: preview,
            scrollPosition: state.scrollPosition
          };
  }
}

function ChapterShow$default(props) {
  var chapter = props.chapter;
  var match = React.useReducer(reducer, {
        view: "Chapter",
        preview: undefined,
        scrollPosition: 0.0
      });
  var dispatch = match[1];
  var state = match[0];
  var scrollTo = function (param) {
    dispatch({
          TAG: "ToggleView",
          _0: undefined
        });
    Scroll.AnimateScroll.scrollTo(state.scrollPosition);
  };
  var setScroll = function ($$event, preview) {
    dispatch({
          TAG: "SetScrollPosition",
          _0: Scroll.scrollTop($$event)
        });
    dispatch({
          TAG: "ToggleView",
          _0: preview
        });
  };
  var match$1 = state.view;
  if (match$1 === "Chapter") {
    var match$2 = chapter.current.number;
    var tmp;
    switch (match$2) {
      case 1 :
          tmp = React.createElement(Chapter01.make, {
                setScroll: setScroll
              });
          break;
      case 2 :
          tmp = React.createElement(Chapter02.make, {
                setScroll: setScroll
              });
          break;
      case 3 :
          tmp = React.createElement(Chapter03.make, {
                setScroll: setScroll
              });
          break;
      case 4 :
          tmp = React.createElement(Chapter04.make, {});
          break;
      default:
        tmp = null;
    }
    return React.createElement(ChapterContainer.make, {
                previous: chapter.previous,
                next: chapter.next,
                children: React.createElement(ReactA11yFootnotes.FootnotesProvider, {
                      children: null
                    }, React.createElement(ChapterTypeIt.make, {
                          messages: [
                            "Chapter " + chapter.current.number.toString(),
                            chapter.current.title
                          ]
                        }), React.createElement(ChapterPage.make, {
                          children: null
                        }, tmp, React.createElement(ReactA11yFootnotes.Footnotes, {})))
              });
  }
  var match$3 = state.preview;
  var tmp$1;
  if (match$3 !== undefined) {
    switch (match$3) {
      case "RailsDefault" :
          tmp$1 = React.createElement(PreviewRailsDefault.make, {});
          break;
      case "HelloWorld" :
          tmp$1 = React.createElement(PreviewHelloWorld.make, {});
          break;
      case "HelloWorldReact" :
          tmp$1 = React.createElement(PreviewHelloWorldReact.make, {});
          break;
      case "RescriptHelloWorld" :
          tmp$1 = React.createElement(PreviewRescriptHelloWorld.make, {
                color: false
              });
          break;
      case "RescriptHelloWorldTailwind" :
          tmp$1 = React.createElement(PreviewRescriptHelloWorldTailwind.make, {});
          break;
      case "RescriptHelloWorldColor" :
          tmp$1 = React.createElement(PreviewRescriptHelloWorld.make, {
                color: true
              });
          break;
      case "RescriptNewHomeInitial" :
          tmp$1 = React.createElement(PreviewNewHomeInitial.make, {});
          break;
      case "RescriptNewHomeColor" :
          tmp$1 = React.createElement(PreviewNewHomeColor.make, {});
          break;
      
    }
  } else {
    tmp$1 = null;
  }
  return React.createElement(FramerMotion.AnimatePresence, {
              children: React.createElement(PreviewBrowser.make, {
                    onClick: scrollTo,
                    children: tmp$1
                  })
            });
}

var Container;

var Page;

var $$default = ChapterShow$default;

export {
  Container ,
  Page ,
  reducer ,
  $$default as default,
}
/* react Not a pure module */
